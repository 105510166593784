var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('v-flex',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"d-flex flex-column align-center text-center",attrs:{"cols":"12"}},[_c('v-img',{staticClass:"logo-class",attrs:{"contain":"","src":"img/logo-virtualProfiler.png"}}),_c('v-card',{staticClass:"mt-12",attrs:{"width":"600"}},[_c('v-card-title',{staticClass:"text-h5 justify-center"},[_vm._v(" Reset password ")]),(
                _vm.token !== null &&
                _vm.token !== undefined &&
                _vm.token !== 'null' &&
                _vm.token !== 'undefined'
              )?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"label":"Password","rules":[_vm.passwordRules.required, _vm.passwordRules.minLength],"outlined":"","dense":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":_vm.togglePasswordVisibility},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"label":"Repeat password","rules":[
                    _vm.confirmPasswordRules.required,
                    _vm.confirmPasswordRules.passwordMatch,
                  ],"outlined":"","dense":"","type":_vm.showConfirmPassword ? 'text' : 'password',"append-icon":_vm.showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click:append":_vm.toggleConfirmPasswordVisibility},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('v-card-actions',[(!_vm.resettingPassword)?_c('v-btn',{attrs:{"color":"primary","block":"","type":"submit","disabled":!_vm.isValid}},[_vm._v(" Reset ")]):_c('v-btn',{attrs:{"color":"primary","block":"","type":"submit","disabled":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1):_c('v-alert',{staticClass:"mx-2",attrs:{"outlined":"","color":'error',"icon":'mdi-alert'}},[_vm._v(" Invalid user credentials, please make sure they are correct. ")]),(_vm.messageReset)?_c('v-alert',{staticClass:"mx-2",attrs:{"outlined":"","color":_vm.successfullReset ? 'success' : 'error',"icon":_vm.successfullReset ? 'mdi-check-circle' : 'mdi-alert',"dismissible":""}},[_vm._v(" "+_vm._s(_vm.messageReset)+" ")]):_vm._e()],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }