import http from "../http-common";

const API_URL_PATH = "positions/";

class PositionsDataService {
  findAll() {
    return http.get(API_URL_PATH);
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(position) {
    return http.post(API_URL_PATH, position);
  }

  update(position) {
    return http.put(API_URL_PATH + position.id, position);
  }

  delete(id) {
    return http.delete(API_URL_PATH + id);
  }
}

export default new PositionsDataService();
