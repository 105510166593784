import http from "../http-common";

const API_URL_PATH = "auth/";

class AuthDataService {
  // register(email, username, password) {
  register(email, username) {
    return http.post(API_URL_PATH + "register", {
      email: email,
      username: username,
    });
  }

  login(email, password) {
    return http.post(API_URL_PATH + "login", {
      email: email,
      password: password,
    });
  }

  activateAccount(token, password) {
    return http.post(API_URL_PATH + "activate-account/" + token, {
      password: password,
    });
  }

  forgotPassword(email) {
    return http.post(API_URL_PATH + "forgot-password", {
      email: email,
    });
  }

  resetPassword(password, token) {
    return http.post(API_URL_PATH + "reset-password", {
      password: password,
      token: token,
    });
  }

  guard() {
    return http.get(API_URL_PATH + "guard");
  }

  logout() {
    return http.get(API_URL_PATH + "logout");
  }
}

export default new AuthDataService();
