<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Component Types</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateComponentType"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create component type
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateComponentType"
                  outlined
                  :color="successfullCreateComponentType ? 'success' : 'error'"
                  :icon="
                    successfullCreateComponentType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateComponentType }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteComponentType"
                  outlined
                  :color="successfullDeleteComponentType ? 'success' : 'error'"
                  :icon="
                    successfullDeleteComponentType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteComponentType }}
                </v-alert>
                <v-alert
                  v-if="messageEditComponentType"
                  outlined
                  :color="successfullEditComponentType ? 'success' : 'error'"
                  :icon="
                    successfullEditComponentType
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditComponentType }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="componentTypes"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openEditComponentType(item)"
                        >
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit component type</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openDeleteComponentType(item)"
                        >
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete component type</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageComponentTypes"
              outlined
              :color="successfullComponentTypes ? 'success' : 'error'"
              :icon="
                successfullComponentTypes ? 'mdi-check-circle' : 'mdi-alert'
              "
              dismissible
            >
              {{ messageComponentTypes }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createComponentTypeDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createComponentTypeForm">
          <v-card-title>
            <h5>Create Component Type</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="componentType.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Code *"
                    v-model="componentType.codeId"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateComponentTypeInside"
                class="mb-0 mt-3"
                outlined
                :color="
                  successfullCreateComponentTypeInside ? 'success' : 'error'
                "
                :icon="
                  successfullCreateComponentTypeInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateComponentTypeInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateComponentType" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn
              @click="createComponentType"
              class="ok-modal"
              v-if="!creatingComponentType"
            >
              Create
            </v-btn>
            <v-btn
              @click="createComponentType"
              class="ok-modal"
              v-else
              disabled
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editComponentTypeDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editComponentTypeForm">
          <v-card-title>
            <h5>Update Component Type</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Code *"
                    v-model="itemToEdit.codeId"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-alert
                v-if="messageEditComponentTypeInside"
                class="mb-0 mt-3"
                outlined
                :color="
                  successfullEditComponentTypeInside ? 'success' : 'error'
                "
                :icon="
                  successfullEditComponentTypeInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditComponentTypeInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="editComponentTypeDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="editComponentType"
              class="ok-modal"
              v-if="!editingComponentType"
            >
              Update
            </v-btn>
            <v-btn @click="editComponentType" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteComponentTypeDialog"
      max-width="550"
      min-height="550"
    >
      <v-card>
        <v-card-title>
          <h5>Delete Component Type</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected component type? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteComponentTypeDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingComponentType">
            <v-btn @click="deleteComponentType" class="ok-modal">
              Confirm
            </v-btn>
          </template>
          <v-btn @click="deleteComponentType" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ComponentsDataService from "@/services/ComponentsDataService";

export default {
  name: "ComponentTypesList",
  data() {
    return {
      componentTypes: [],
      successfullComponentTypes: false,
      messageComponentTypes: "",
      loading: false,
      successfullCreateComponentType: false,
      messageCreateComponentType: "",
      successfullCreateComponentTypeInside: false,
      messageCreateComponentTypeInside: "",
      successfullEditComponentType: false,
      messageEditComponentType: "",
      successfullEditComponentTypeInside: false,
      messageEditComponentTypeInside: "",
      successfullDeleteComponentType: false,
      messageDeleteComponentType: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Code", value: "codeId" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      componentType: {
        name: "",
        codeId: "",
      },
      rules: [(v) => !!v || "Required field"],
      createComponentTypeDialog: false,
      creatingComponentType: false,
      editComponentTypeDialog: false,
      editingComponentType: false,
      deleteComponentTypeDialog: false,
      deletingComponentType: false,
      itemToEdit: {},
      itemToDelete: {},
    };
  },
  async mounted() {
    await this.getAllComponentTypes();
  },
  methods: {
    getAllComponentTypes() {
      this.loading = true;

      ComponentsDataService.findAllComponentTypes().then(
        (response) => {
          if (response.status == 200) this.componentTypes = response.data.data;
          else {
            this.successfullComponentTypes = false;
            this.messageComponentTypes =
              "An error has occurred retrieving the list of component types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullComponentTypes = false;
          this.messageComponentTypes =
            "An error has occurred retrieving the list of component types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    openCreateComponentType() {
      this.createComponentTypeDialog = true;
    },
    createComponentType() {
      this.successfullCreateComponentType = true;
      this.messageCreateComponentType = "";

      if (this.$refs.createComponentTypeForm.validate()) {
        this.creatingComponentType = true;

        ComponentsDataService.createComponentType(this.componentType).then(
          (response) => {
            if (response.status == 201) {
              this.getAllComponentTypes();

              this.messageCreateComponentType = "";
              this.successfullCreateComponentType = true;

              setTimeout(() => {
                this.messageCreateComponentType =
                  "Component type successfully created.";
              }, 10);
            } else {
              this.messageCreateComponentType = "";
              this.successfullCreateComponentType = true;

              setTimeout(() => {
                this.messageCreateComponentType =
                  "An error has occurred creating the component type. Please, try again later.";
              }, 10);
            }

            this.createComponentTypeDialog = false;
            this.creatingComponentType = false;
            this.componentType = {
              name: "",
              codeId: "",
            };
          },
          (error) => {
            this.messageCreateComponentTypeInside = "";
            this.messageCreateComponentType = "";

            if (error.response.status === 409) {
              this.successfullCreateComponentTypeInside = false;

              setTimeout(() => {
                this.messageCreateComponentTypeInside =
                  "Component type already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullCreateComponentType = false;

              setTimeout(() => {
                this.messageCreateComponentType =
                  "An error has occurred creating the component type. Please, try again later.";
              }, 10);

              this.createComponentTypeDialog = false;
              this.componentType = {
                name: "",
                codeId: "",
              };
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.creatingComponentType = false;
          }
        );
      }
    },
    cancelCreateComponentType() {
      this.createComponentTypeDialog = false;
      this.componentType = {
        name: "",
        codeId: "",
      };
    },
    openEditComponentType(componentType) {
      this.itemToEdit = { ...componentType };
      this.editComponentTypeDialog = true;
    },
    editComponentType() {
      this.successfullEditComponentType = true;

      if (this.$refs.editComponentTypeForm.validate()) {
        this.editingComponentType = true;

        ComponentsDataService.updateComponentType(this.itemToEdit).then(
          (response) => {
            if (response.status == 204) {
              this.getAllComponentTypes();
              this.messageEditComponentType = "";
              this.successfullEditComponentType = true;

              setTimeout(() => {
                this.messageEditComponentType =
                  "Component type successfully updated.";
              }, 10);
            } else {
              this.messageEditComponentType = "";
              this.successfullEditComponentType = true;

              setTimeout(() => {
                this.messageEditComponentType =
                  "An error has occurred updating the component type. Please, try again later.";
              }, 10);
            }

            this.editComponentTypeDialog = false;
            this.editingComponentType = false;
            this.itemToEdit = {};
          },
          (error) => {
            this.messageEditComponentTypeInside = "";
            this.messageEditComponentType = "";

            if (error.response.status === 409) {
              this.successfullEditComponentTypeInside = false;

              setTimeout(() => {
                this.messageEditComponentTypeInside =
                  "Component type already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditComponentType = false;

              setTimeout(() => {
                this.messageEditComponentType =
                  "An error has occurred updating the component type. Please, try again later.";
              }, 10);

              this.editComponentTypeDialog = false;
              this.itemToEdit = {};
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingComponentType = false;
          }
        );
      }
    },
    openDeleteComponentType(componentType) {
      this.itemToDelete = { ...componentType };
      this.deleteComponentTypeDialog = true;
    },
    deleteComponentType() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingComponentType = true;

        ComponentsDataService.deleteComponentType(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllComponentTypes();
              this.messageDeleteComponentType = "";
              this.successfullDeleteComponentType = true;

              setTimeout(() => {
                this.messageDeleteComponentType =
                  "Component type successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteComponentType = "";
              this.successfullDeleteComponentType = false;

              setTimeout(() => {
                this.messageDeleteComponentType =
                  "An error has occurred deleting the component type. Please, try again later.";
              }, 10);
            }

            this.deleteComponentTypeDialog = false;
            this.deletingComponentType = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteComponentType = "";
            this.successfullDeleteComponentType = false;

            setTimeout(() => {
              this.messageDeleteComponentType =
                "An error has occurred deleting the component type. Please, try again later.";
            }, 10);

            this.deleteComponentTypeDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingComponentType = false;
          }
        );
      } else {
        this.messageDeleteComponentType = "";
        this.successfullDeleteComponentType = false;

        setTimeout(() => {
          this.messageDeleteComponentType =
            "An error has occurred deleting the component type. Please, try again later.";
        }, 10);

        this.deleteComponentTypeDialog = false;
        this.itemToDelete = {};
      }
    },
  },
};
</script>
