import Vue from "vue";
import VueRouter from "vue-router";

import LandingPage from "../views/LandingPage.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ActivateAccount from "../views/ActivateAccount.vue";
import Home from "../views/Home.vue";
import JobsList from "../views/JobsList.vue";
import ContextsList from "../views/ContextsList.vue";
import CompetenciesList from "../views/CompetenciesList.vue";
import PositionsList from "../views/PositionsList.vue";
import InterviewTypesList from "../views/InterviewTypesList.vue";
import ComponentTypesList from "../views/ComponentTypesList.vue";
import ComponentsList from "../views/ComponentsList.vue";
import InterviewAnalysisTypesList from "../views/InterviewAnalysisTypesList.vue";
import EnvironmentBackgroundsList from "../views/EnvironmentBackgroundsList.vue";
import VirtualProfilerList from "../views/VirtualProfilerList.vue";
import VirtualProfilerTypesList from "../views/VirtualProfilerTypesList.vue";
import VirtualProfilerAnimationsList from "../views/VirtualProfilerAnimationsList.vue";
import CandidatesList from "../views/CandidatesList.vue";
import UsersList from "../views/UsersList.vue";
import UploadCV from "../views/UploadCV.vue";
import CvInfo from "../views/CvInfo.vue";
import WaitingRoom from "../views/WaitingRoom.vue";
import WaitingRoomCandidate from "../views/WaitingRoomCandidate.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import Interview from "@/views/Interview.vue";

// Vue Upload
import VueFileAgent from "vue-file-agent";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";

// MDI Icons
import "@mdi/font/css/materialdesignicons.min.css";

// Moment
import moment from "moment";

Vue.use(VueRouter);
Vue.use(VueFileAgent);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD HH:mm");
  }
});

Vue.filter("slice20Characters", function (value) {
  if (value) {
    return value.length >= 20 ? value.slice(0, 20) + "..." : value;
  }
});

Vue.filter("slice40Characters", function (value) {
  if (value) {
    return value.length >= 40 ? value.slice(0, 40) + "..." : value;
  }
});

function getCurrentUser() {
  return localStorage.getItem("user") ? true : false;
}

const routes = [
  { path: "/", name: "landing page", component: LandingPage },
  {
    path: "/activate-account",
    name: "activate account",
    component: ActivateAccount,
  },
  { path: "/reset-password", name: "reset password", component: ResetPassword },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/jobs",
    name: "jobs list",
    component: JobsList,
    meta: { requiresAuth: true },
  },
  {
    path: "/contexts",
    name: "contexts list",
    component: ContextsList,
    meta: { requiresAuth: true },
  },
  {
    path: "/competencies",
    name: "competencies list",
    component: CompetenciesList,
    meta: { requiresAuth: true },
  },
  {
    path: "/positions",
    name: "positions list",
    component: PositionsList,
    meta: { requiresAuth: true },
  },
  {
    path: "/interview-types",
    name: "interview types list",
    component: InterviewTypesList,
    meta: { requiresAuth: true },
  },
  {
    path: "/component-types",
    name: "component types list",
    component: ComponentTypesList,
    meta: { requiresAuth: true },
  },
  {
    path: "/components",
    name: "components list",
    component: ComponentsList,
    meta: { requiresAuth: true },
  },
  {
    path: "/interview-analysis-types",
    name: "interview analysis types list",
    component: InterviewAnalysisTypesList,
    meta: { requiresAuth: true },
  },
  {
    path: "/environment-backgrounds",
    name: "environment backgrounds list",
    component: EnvironmentBackgroundsList,
    meta: { requiresAuth: true },
  },
  {
    path: "/virtual-profilers",
    name: "virtual profilers list",
    component: VirtualProfilerList,
    meta: { requiresAuth: true },
  },
  {
    path: "/virtual-profiler-types",
    name: "virtual profilers types list",
    component: VirtualProfilerTypesList,
    meta: { requiresAuth: true },
  },
  {
    path: "/virtual-profiler-animations",
    name: "virtual profilers animations list",
    component: VirtualProfilerAnimationsList,
    meta: { requiresAuth: true },
  },
  {
    path: "/candidates",
    name: "candidates list",
    component: CandidatesList,
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "users list",
    component: UsersList,
    meta: { requiresAuth: true },
  },
  {
    path: "/upload-cv",
    name: "upload cv",
    component: UploadCV,
    meta: { requiresAuth: true },
  },
  {
    path: "/cv-info",
    name: "cv info",
    component: CvInfo,
    meta: { requiresAuth: true },
  },
  {
    path: "/waiting-room",
    name: "waiting room",
    component: WaitingRoom,
    meta: { requiresAuth: true },
  },
  {
    path: "/waiting-room-candidate",
    name: "waiting room candidate",
    component: WaitingRoomCandidate,
  },
  {
    path: "/error",
    name: "error",
    component: ErrorPage,
  },
  { path: "/interview", name: "interview", component: Interview },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const currentUser = getCurrentUser();

  if (requiresAuth && !currentUser) next("/");
  else next();
});

export default router;
