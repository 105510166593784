<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Jobs</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateJob"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create job
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateJob"
                  outlined
                  :color="successfullCreateJob ? 'success' : 'error'"
                  :icon="
                    successfullCreateJob ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateJob }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteJob"
                  outlined
                  :color="successfullDeleteJob ? 'success' : 'error'"
                  :icon="
                    successfullDeleteJob ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteJob }}
                </v-alert>
                <v-alert
                  v-if="messageEditJob"
                  outlined
                  :color="successfullEditJob ? 'success' : 'error'"
                  :icon="successfullEditJob ? 'mdi-check-circle' : 'mdi-alert'"
                  dismissible
                >
                  {{ messageEditJob }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="jobs"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openEditJob(item)">
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit job</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openDeleteJob(item)">
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete job</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageJobs"
              outlined
              :color="successfullJobs ? 'success' : 'error'"
              :icon="successfullJobs ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageJobs }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageContexts"
              outlined
              :color="successfullContexts ? 'success' : 'error'"
              :icon="successfullContexts ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageContexts }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createJobDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createJobForm">
          <v-card-title>
            <h5>Create Job</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="job.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-select
                    label="Contexts"
                    :items="contexts"
                    v-model="job.contexts"
                    item-text="name"
                    item-value="id"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <p class="information-text">
                    <v-icon size="20" color="primary">mdi-information</v-icon
                    >&nbsp;&nbsp;It is not necessary for you to select any
                    context right now. However, please, note that the order of
                    the contexts will affect their use in the avatar.
                  </p>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateJobInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullCreateJobInside ? 'success' : 'error'"
                :icon="
                  successfullCreateJobInside ? 'mdi-check-circle' : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateJobInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateJob" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn @click="createJob" class="ok-modal" v-if="!creatingJob">
              Create
            </v-btn>
            <v-btn @click="createJob" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editJobDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editJobForm">
          <v-card-title>
            <h5>Update Job</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-select
                    label="Contexts"
                    :items="contexts"
                    v-model="itemToEdit.contexts"
                    item-text="name"
                    item-value="id"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <p class="information-text">
                    <v-icon size="20" color="primary">mdi-information</v-icon
                    >&nbsp;&nbsp;Please, note that the order of the contexts
                    will affect their use in the avatar.
                  </p>
                </v-col>
              </v-row>

              <v-alert
                v-if="messageEditJobInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullEditJobInside ? 'success' : 'error'"
                :icon="
                  successfullEditJobInside ? 'mdi-check-circle' : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditJobInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="editJobDialog = false" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn @click="editJob" class="ok-modal" v-if="!editingJob">
              Update
            </v-btn>
            <v-btn @click="editJob" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteJobDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete Job</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected job? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="deleteJobDialog = false" class="cancel-modal">
            Cancel
          </v-btn>

          <template v-if="!deletingJob">
            <v-btn @click="deleteJob" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="deleteJob" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsJobDialog" max-width="800">
      <v-card>
        <v-form ref="detailsJobForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  itemToShow.contexts !== undefined &&
                  itemToShow.contexts.length == 0
                "
              >
                <v-col cols="2">
                  <h6 class="details-subtitles">Contexts:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">Job with no associated contexts</p>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="12" class="pb-0">
                  <h6 class="details-subtitles">Contexts:</h6>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-data-table
                    :headers="headersDetails"
                    :items="itemToShow.contexts"
                    item-key="itemToShow.id"
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsJobDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import JobsDataService from "@/services/JobsDataService";
import ContextsDataService from "@/services/ContextsDataService";

export default {
  name: "JobsList",
  data() {
    return {
      jobs: [],
      successfullJobs: false,
      messageJobs: "",
      contexts: [],
      successfullContexts: false,
      messageContexts: "",
      loading: false,
      successfullCreateJob: false,
      messageCreateJob: "",
      successfullCreateJobInside: false,
      messageCreateJobInside: "",
      successfullEditJob: false,
      messageEditJob: "",
      successfullEditJobInside: false,
      messageEditJobInside: "",
      successfullDeleteJob: false,
      messageDeleteJob: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Number of contexts", value: "contexts.length" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      job: {
        name: "",
        contexts: [],
        status: 1,
      },
      rules: [(v) => !!v || "Required field"],
      createJobDialog: false,
      creatingJob: false,
      editJobDialog: false,
      editingJob: false,
      deleteJobDialog: false,
      deletingJob: false,
      itemToEdit: {},
      itemToDelete: {},
      itemToShow: {},
      headersDetails: [
        { text: "Name", value: "name" },
        { text: "Content", value: "content" },
      ],
      showDetailsJobDialog: false,
    };
  },
  async mounted() {
    await this.getAllContexts();
    await this.getAllJobs();
  },
  methods: {
    getAllJobs() {
      this.loading = true;

      JobsDataService.findAll().then(
        (response) => {
          if (response.status == 200) this.jobs = response.data.data;
          else {
            this.successfullJobs = false;
            this.messageJobs =
              "An error has occurred retrieving the list of jobs. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullJobs = false;
          this.messageJobs =
            "An error has occurred retrieving the list of jobs. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getAllContexts() {
      this.loading = true;

      ContextsDataService.findAll().then(
        (response) => {
          if (response.status == 200) this.contexts = response.data.data;
          else {
            this.successfullContexts = false;
            this.messageContexts =
              "An error has occurred retrieving the list of contexts. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullContexts = false;
          this.messageContexts =
            "An error has occurred retrieving the list of contexts. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    openCreateJob() {
      this.createJobDialog = true;
    },
    createJob() {
      this.successfullCreateJob = true;
      this.messageCreateJob = "";

      if (this.$refs.createJobForm.validate()) {
        this.creatingJob = true;

        JobsDataService.create(this.job).then(
          (response) => {
            if (response.status == 201) {
              this.getAllJobs();

              this.messageCreateJob = "";
              this.successfullCreateJob = true;

              setTimeout(() => {
                this.messageCreateJob = "Job successfully created.";
              }, 10);
            } else {
              this.messageCreateJob = "";
              this.successfullCreateJob = true;

              setTimeout(() => {
                this.messageCreateJob =
                  "An error has occurred creating the job. Please, try again later.";
              }, 10);
            }

            this.createJobDialog = false;
            this.creatingJob = false;
            this.job = {
              name: "",
              contexts: [],
              status: 1,
            };
          },
          (error) => {
            this.messageCreateJobInside = "";
            this.messageCreateJob = "";

            if (error.response.status === 409) {
              this.successfullCreateJobInside = false;

              setTimeout(() => {
                this.messageCreateJobInside =
                  "Job already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullCreateJob = false;

              setTimeout(() => {
                this.messageCreateJob =
                  "An error has occurred creating the job. Please, try again later.";
              }, 10);

              this.createJobDialog = false;
              this.job = {
                name: "",
                contexts: [],
                status: 1,
              };
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.creatingJob = false;
          }
        );
      }
    },
    cancelCreateJob() {
      this.createJobDialog = false;
      this.job = {
        name: "",
        contexts: [],
        status: 1,
      };
    },
    openEditJob(job) {
      this.itemToEdit = { ...job };

      let itemContexts = [...job.contexts];

      this.itemToEdit.contexts = [];
      itemContexts.forEach((context) => {
        this.itemToEdit.contexts.push(context.id);
      });

      this.editJobDialog = true;
    },
    editJob() {
      this.successfullEditJob = true;

      if (this.$refs.editJobForm.validate()) {
        this.editingJob = true;

        JobsDataService.update(this.itemToEdit).then(
          (response) => {
            if (response.status == 204) {
              this.getAllJobs();
              this.messageEditJob = "";
              this.successfullEditJob = true;

              setTimeout(() => {
                this.messageEditJob = "Job successfully updated.";
              }, 10);
            } else {
              this.messageEditJob = "";
              this.successfullEditJob = true;

              setTimeout(() => {
                this.messageEditJob =
                  "An error has occurred updating the job. Please, try again later.";
              }, 10);
            }

            this.editJobDialog = false;
            this.editingJob = false;
            this.itemToEdit = {};
          },
          (error) => {
            this.messageEditJobInside = "";
            this.messageEditJob = "";

            if (error.response.status === 409) {
              this.successfullEditJobInside = false;

              setTimeout(() => {
                this.messageEditJobInside =
                  "Job already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditJob = false;

              setTimeout(() => {
                this.messageEditJob =
                  "An error has occurred updating the job. Please, try again later.";
              }, 10);

              this.editJobDialog = false;
              this.itemToEdit = {};
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingJob = false;
          }
        );
      }
    },
    openDeleteJob(job) {
      this.itemToDelete = { ...job };
      this.deleteJobDialog = true;
    },
    deleteJob() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingJob = true;

        JobsDataService.delete(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllJobs();
              this.messageDeleteJob = "";
              this.successfullDeleteJob = true;

              setTimeout(() => {
                this.messageDeleteJob = "Job successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteJob = "";
              this.successfullDeleteJob = false;

              setTimeout(() => {
                this.messageDeleteJob =
                  "An error has occurred deleting the job. Please, try again later.";
              }, 10);
            }

            this.deleteJobDialog = false;
            this.deletingJob = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteJob = "";
            this.successfullDeleteJob = false;

            setTimeout(() => {
              this.messageDeleteJob =
                "An error has occurred deleting the job. Please, try again later.";
            }, 10);

            this.deleteJobDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingJob = false;
          }
        );
      } else {
        this.messageDeleteJob = "";
        this.successfullDeleteJob = false;

        setTimeout(() => {
          this.messageDeleteJob =
            "An error has occurred deleting the job. Please, try again later.";
        }, 10);

        this.deleteJobDialog = false;
        this.itemToDelete = {};
      }
    },
    openShowDetails(job) {
      this.itemToShow = { ...job };
      this.showDetailsJobDialog = true;
    },
  },
};
</script>
