import http from "../http-common";

const API_URL_PATH = "environment-backgrounds/";

class EnvironmentBackgroundsDataService {
  findAll() {
    return http.get(API_URL_PATH);
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(environmentBackground, onUploadEnvironmentBackground) {
    return http.post(API_URL_PATH, environmentBackground, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadEnvironmentBackground,
    });
  }

  update(environmentBackground, onUploadEnvironmentBackground) {
    return http.put(
      API_URL_PATH + environmentBackground.id,
      environmentBackground,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadEnvironmentBackground,
      }
    );
  }

  delete(id) {
    return http.delete(API_URL_PATH + id);
  }
}

export default new EnvironmentBackgroundsDataService();
