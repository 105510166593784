<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Virtual Profiler Types</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateType"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create virtual profiler type
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateType"
                  outlined
                  :color="successfullCreateType ? 'success' : 'error'"
                  :icon="
                    successfullCreateType ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateType }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteType"
                  outlined
                  :color="successfullDeleteType ? 'success' : 'error'"
                  :icon="
                    successfullDeleteType ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteType }}
                </v-alert>
                <v-alert
                  v-if="messageEditType"
                  outlined
                  :color="successfullEditType ? 'success' : 'error'"
                  :icon="successfullEditType ? 'mdi-check-circle' : 'mdi-alert'"
                  dismissible
                >
                  {{ messageEditType }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="types"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openEditType(item)">
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit virtual profiler type</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openDeleteType(item)">
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete virtual profiler type</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-alert
              class="mt-4"
              v-if="messageTypes"
              outlined
              :color="successfullTypes ? 'success' : 'error'"
              :icon="successfullTypes ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageTypes }}
            </v-alert>
            <v-alert
              class="mt-4"
              v-if="messageAnimations"
              outlined
              :color="successfullAnimations ? 'success' : 'error'"
              :icon="successfullAnimations ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageAnimations }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createTypeDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createTypeForm">
          <v-card-title>
            <h5>Create Virtual Profiler Type</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="type.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Description *"
                    v-model="type.description"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    :items="animations"
                    item-text="name"
                    item-value="id"
                    label="Animation *"
                    v-model="type.animationId"
                    :rules="rules"
                  ></v-select>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateTypeInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullCreateTypeInside ? 'success' : 'error'"
                :icon="
                  successfullCreateTypeInside ? 'mdi-check-circle' : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateTypeInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateType" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn @click="createType" class="ok-modal" v-if="!creatingType">
              Create
            </v-btn>
            <v-btn @click="createType" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editTypeDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editTypeForm">
          <v-card-title>
            <h5>Update Virtual Profiler Type</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Description *"
                    v-model="itemToEdit.description"
                    :rules="rules"
                  ></v-text-field
                ></v-col>

                <v-col cols="12" class="py-0">
                  <v-select
                    :items="animations"
                    item-text="name"
                    item-value="id"
                    label="Animation *"
                    v-model="itemToEdit.animationId"
                    :rules="rules"
                  ></v-select>
                </v-col>
              </v-row>

              <v-alert
                v-if="messageEditTypeInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullEditTypeInside ? 'success' : 'error'"
                :icon="
                  successfullEditTypeInside ? 'mdi-check-circle' : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditTypeInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="editTypeDialog = false" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn @click="editType" class="ok-modal" v-if="!editingType">
              Update
            </v-btn>
            <v-btn @click="editType" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteTypeDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete Virtual Profiler Type</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected virtual profiler type?
            <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="deleteTypeDialog = false" class="cancel-modal">
            Cancel
          </v-btn>

          <template v-if="!deletingType">
            <v-btn @click="deleteType" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="deleteType" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsTypeDialog" max-width="800">
      <v-card>
        <v-form ref="detailsTypeForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Description:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.description }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Animation:</h6>
                </v-col>
                <v-col cols="10">
                  <p
                    class="mb-0"
                    v-if="
                      itemToShow.animations !== undefined &&
                      itemToShow.animations.length > 0
                    "
                  >
                    {{ itemToShow.animations[0].name }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsTypeDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VirtualProfilersDataService from "@/services/VirtualProfilersDataService";

export default {
  name: "VirtualProfilerTypesList",
  data() {
    return {
      types: [],
      successfullTypes: false,
      messageTypes: "",
      animations: [],
      successfullAnimations: false,
      messageAnimations: "",
      loading: false,
      successfullCreateType: false,
      messageCreateType: "",
      successfullCreateTypeInside: false,
      messageCreateTypeInside: "",
      successfullEditType: false,
      messageEditType: "",
      successfullEditTypeInside: false,
      messageEditTypeInside: "",
      successfullDeleteType: false,
      messageDeleteType: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Animation", value: "animations[0].name" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      type: {
        name: "",
        description: "",
        animationIds: [],
        animationId: null,
      },
      rules: [(v) => !!v || "Required field"],
      createTypeDialog: false,
      creatingType: false,
      editTypeDialog: false,
      editingType: false,
      deleteTypeDialog: false,
      deletingType: false,
      itemToEdit: {},
      itemToDelete: {},
      itemToShow: {},
      showDetailsTypeDialog: false,
    };
  },
  async mounted() {
    await this.getAllTypes();
    await this.getAllAnimations();
  },
  methods: {
    getAllTypes() {
      this.loading = true;

      VirtualProfilersDataService.findAllVirtualProfilerTypes().then(
        (response) => {
          if (response.status == 200) this.types = response.data.data;
          else {
            this.successfullTypes = false;
            this.messageTypes =
              "An error has occurred retrieving the list of virtual profiler types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullTypes = false;
          this.messageTypes =
            "An error has occurred retrieving the list of virtual profiler types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getAllAnimations() {
      this.loading = true;

      VirtualProfilersDataService.findAllVirtualProfilerAnimations().then(
        (response) => {
          if (response.status == 200) this.animations = response.data.data;
          else {
            this.successfullAnimations = false;
            this.messageAnimations =
              "An error has occurred retrieving the list of animations. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullAnimations = false;
          this.messageAnimations =
            "An error has occurred retrieving the list of animations. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    openCreateType() {
      this.createTypeDialog = true;
      this.type = {
        name: "",
        description: "",
        animationIds: [],
        animationId: null,
      };
    },
    createType() {
      this.successfullCreateType = true;
      this.messageCreateType = "";

      if (this.$refs.createTypeForm.validate()) {
        this.creatingType = true;

        if (this.type.animationId !== null) {
          this.type.animationIds = [this.type.animationId];

          VirtualProfilersDataService.createVirtualProfilerType(this.type).then(
            (response) => {
              if (response.status == 201) {
                this.getAllTypes();

                this.messageCreateType = "";
                this.successfullCreateType = true;

                setTimeout(() => {
                  this.messageCreateType = "Type successfully created.";
                }, 10);
              } else {
                this.messageCreateType = "";
                this.successfullCreateType = true;

                setTimeout(() => {
                  this.messageCreateType =
                    "An error has occurred creating the type. Please, try again later.";
                }, 10);
              }

              this.createTypeDialog = false;
              this.creatingType = false;
              this.type = {
                name: "",
                description: "",
                animationIds: [],
                animationId: null,
              };
            },
            (error) => {
              this.messageCreateTypeInside = "";
              this.messageCreateType = "";

              if (error.response.status === 409) {
                this.successfullCreateTypeInside = false;

                setTimeout(() => {
                  this.messageCreateTypeInside =
                    "Type already in use. Please, change its name.";
                }, 10);
              } else if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.successfullCreateType = false;

                setTimeout(() => {
                  this.messageCreateType =
                    "An error has occurred creating the type. Please, try again later.";
                }, 10);

                this.createTypeDialog = false;
                this.type = {
                  name: "",
                  description: "",
                  animationIds: [],
                  animationId: null,
                };
              }

              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              this.creatingType = false;
            }
          );
        } else {
          this.successfullCreateTypeInside = false;

          setTimeout(() => {
            this.messageCreateTypeInside =
              "Please, select an animation for the type.";
          }, 10);

          this.creatingType = false;
        }
      }
    },
    cancelCreateType() {
      this.createTypeDialog = false;
      this.type = {
        name: "",
        description: "",
        animationIds: [],
        animationId: null,
      };
    },
    openEditType(type) {
      this.itemToEdit = { ...type };
      this.itemToEdit.animationId = this.itemToEdit.animations[0].id;
      this.editTypeDialog = true;
    },
    editType() {
      this.successfullEditType = true;

      if (this.$refs.editTypeForm.validate()) {
        this.editingType = true;
        if (this.itemToEdit.animationId !== null) {
          this.itemToEdit.animationIds = [this.itemToEdit.animationId];

          VirtualProfilersDataService.updateVirtualProfilerType(
            this.itemToEdit
          ).then(
            (response) => {
              if (response.status == 204) {
                this.getAllTypes();
                this.messageEditType = "";
                this.successfullEditType = true;

                setTimeout(() => {
                  this.messageEditType = "Type successfully updated.";
                }, 10);
              } else {
                this.messageEditType = "";
                this.successfullEditType = true;

                setTimeout(() => {
                  this.messageEditType =
                    "An error has occurred updating the type. Please, try again later.";
                }, 10);
              }

              this.editTypeDialog = false;
              this.editingType = false;
              this.itemToEdit = {};
            },
            (error) => {
              this.messageEditTypeInside = "";
              this.messageEditType = "";

              if (error.response.status === 409) {
                this.successfullEditTypeInside = false;

                setTimeout(() => {
                  this.messageEditTypeInside =
                    "Type already in use. Please, change its name.";
                }, 10);
              } else if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.successfullEditType = false;

                setTimeout(() => {
                  this.messageEditType =
                    "An error has occurred updating the type. Please, try again later.";
                }, 10);

                this.editTypeDialog = false;
                this.itemToEdit = {};
              }

              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              this.editingType = false;
            }
          );
        } else {
          this.successfullEditTypeInside = false;

          setTimeout(() => {
            this.messageEditTypeInside =
              "Please, select an animation for the type.";
          }, 10);

          this.editingType = false;
        }
      }
    },
    openDeleteType(type) {
      this.itemToDelete = { ...type };
      this.deleteTypeDialog = true;
    },
    deleteType() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingType = true;

        VirtualProfilersDataService.deleteVirtualProfilerType(
          this.itemToDelete.id
        ).then(
          (response) => {
            if (response.status == 204) {
              this.getAllTypes();
              this.messageDeleteType = "";
              this.successfullDeleteType = true;

              setTimeout(() => {
                this.messageDeleteType = "Type successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteType = "";
              this.successfullDeleteType = false;

              setTimeout(() => {
                this.messageDeleteType =
                  "An error has occurred deleting the type. Please, try again later.";
              }, 10);
            }

            this.deleteTypeDialog = false;
            this.deletingType = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteType = "";
            this.successfullDeleteType = false;

            setTimeout(() => {
              this.messageDeleteType =
                "An error has occurred deleting the type. Please, try again later.";
            }, 10);

            this.deleteTypeDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingType = false;
          }
        );
      } else {
        this.messageDeleteType = "";
        this.successfullDeleteType = false;

        setTimeout(() => {
          this.messageDeleteType =
            "An error has occurred deleting the type. Please, try again later.";
        }, 10);

        this.deleteTypeDialog = false;
        this.itemToDelete = {};
      }
    },
    openShowDetails(type) {
      this.itemToShow = { ...type };
      this.showDetailsTypeDialog = true;
    },
  },
};
</script>
