import http from "../http-common";

const API_URL_PATH = "competencies/";
const API_URL_PATH_INFO = "utils/competencies/info";

class CompetenciesDataService {
  findAll() {
    return http.get(API_URL_PATH);
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(competence) {
    return http.post(API_URL_PATH, competence);
  }

  update(competence) {
    return http.put(API_URL_PATH + competence.id, competence);
  }

  delete(id) {
    return http.delete(API_URL_PATH + id);
  }

  getInfo() {
    return http.get(API_URL_PATH_INFO);
  }
}

export default new CompetenciesDataService();
