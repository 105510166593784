import { io } from "socket.io-client";
import Vue from "vue";
import utils from "@/utils/utils";

class SocketioService {
  emisor = new Vue();
  callRoom = "";

  setupSocketConnection(id, username, isInterviewer) {
    const socket = io(
      `${utils.api.protocol}://${utils.api.host}:${utils.api.port}`,
      { withCredentials: true }
    );

    if (isInterviewer) this.sendInterviewerId(socket, id, username);
    else this.sendCandidateId(socket, id);

    socket.on("add-interviewer", (data) => {
      Vue.prototype.$socketId = data.socketId;
    });

    socket.on("add-candidate", (data) => {
      Vue.prototype.$socketId = data.socketId;
    });

    socket.on("get-notifications", (data) => {
      this.emisor.$emit(
        "notificationsSended",
        data.map((msg) => JSON.parse(msg))
      );
    });

    socket.on("cv-completed", (data) => {
      this.emisor.$emit("notificationsActiveSended", JSON.parse(data.msg));
    });

    socket.on("cv-error", (data) => {
      this.emisor.$emit("notificationsActiveSended", JSON.parse(data.msg));
    });

    socket.on("dismiss-notification", (data) => {
      this.emisor.$emit("notificationDismissed", data.response);
    });

    socket.on("call-room", (data) => {
      console.log("call-room", data);
      this.emisor.$emit("callRoomResponse", data);
    });

    socket.on("call-open", (data) => {
      this.emisor.$emit("callOpenResponse", data);
    });

    socket.on("call-close", (data) => {
      this.emisor.$emit("callCloseResponse", data);
    });

    socket.on("call-user-join", (data) => {
      console.log("call-user-join", data);
      this.emisor.$emit("callUserJoinResponse", data);
    });

    socket.on("call-user-opt", (data) => {
      this.emisor.$emit("callUserOptResponse", data);
    });

    socket.on("chat-user-message", (data) => {
      this.emisor.$emit("callUserChatMsgResponse", data);
    });

    socket.on("call-user-leave", (data) => {
      this.emisor.$emit("callUserLeaveResponse", data);
    });

    socket.on("call-user-finished", (data) => {
      this.emisor.$emit("callUserFinishedResponse", data);
    });

    socket.on("call-messages", (data) => {
      console.log("call-messages", data);
      this.emisor.$emit("callMessagesResponse", data);
    });

    return socket;
  }

  disconnect(socket) {
    if (socket) {
      socket.disconnect();
    }
  }

  sendInterviewerId(socket, interviewerId, interviewerUsername) {
    socket.emit("add-interviewer", {
      interviewer: { id: interviewerId, username: interviewerUsername },
    });
  }

  sendCandidateId(socket, candidateId) {
    socket.emit("add-candidate", { candidate: candidateId });
  }

  dismissNotification(socket, index) {
    socket.emit("dismiss-notification", { index: index });
  }

  createCallRoom(socket, callId) {
    socket.emit("call-room", { callId: callId });
  }

  joinCall(socket, peerId, camera, micro) {
    console.log("joinCall en el socket service", peerId, camera, micro);
    socket.emit("call-join", {
      peerId: peerId,
      video: camera,
      audio: micro,
      serializations: ["binary", "binary-utf8", "json"],
    });
  }

  leaveCall(socket, isCandidate) {
    socket.emit("call-leave");
    if (!isCandidate) {
      socket.emit("call-finished"); // AQUI SE ENVIA PARA ACABAR LA ENTREVISTA
    }

    return true;
  }

  callOptions(socket, camera, micro) {
    socket.emit("call-opt", { video: camera, audio: micro, hand: false });
  }

  chatMessage(socket, message, timeSended) {
    socket.emit("call-chat-msg", { content: message, timeSended: timeSended });
  }

  retrievePreviousMessages(socket) {
    socket.emit("chat-messages");
  }
}

export default new SocketioService();
