import { Socket } from "socket.io-client";
import http from "../http-common";
const API_URL_PATH = "videos";

class VideosDataService {
  uploadVideo(media, socketId, callId, candidateId) {
    console.log(
      "upload video en el data service",
      media,
      socketId,
      callId,
      candidateId
    );
    const handlerKeys = {
      audio: {
        candidate: "audioCandidate",
        interviewer: "audioInterviewer",
      },
      video: {
        candidate: "videoCandidate",
        interviewer: "videoInterviewer",
      },
    };

    const form = new FormData();

    media.forEach((res) => {
      if (handlerKeys[res.type][res.source] !== "videoInterviewer")
        form.append(handlerKeys[res.type][res.source], res.blob);
    });

    form.append("callId", callId);
    form.append("socketId", socketId);
    form.append("candidateId", candidateId);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    return http.post(API_URL_PATH, form, { headers: headers });
  }
}

export default new VideosDataService();
