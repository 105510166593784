<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Access to the interview</h1>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col col-6">
                <v-row class="my-0 mx-6">
                  <h6>Name:</h6>
                  <v-col class="col col-12">
                    <p>{{ this.$route.params.interview.name }}</p>
                  </v-col>
                </v-row>
                <v-row class="my-0 mx-6">
                  <h6>Description:</h6>
                  <v-col class="col col-12">
                    <p>{{ this.$route.params.interview.description }}</p>
                  </v-col>
                </v-row>
                <v-row
                  class="my-0 mx-6"
                  v-if="this.$route.params.interview.candidateId !== undefined"
                >
                  <h6>Candidate:</h6>
                  <v-col class="col col-12">
                    <p>{{ candidateName }}</p>
                  </v-col>
                </v-row>
                <v-row class="my-0 mx-6">
                  <h6>Position:</h6>
                  <v-col class="col col-12">
                    <p>{{ this.$route.params.interview.position.name }}</p>
                  </v-col>
                </v-row>
                <v-row class="my-0 mx-6">
                  <h6>Competencies:</h6>
                  <v-col class="col col-12 py-0">
                    <v-list color="transparent" class="py-0">
                      <v-list-item
                        v-for="(competence, index) in this.$route.params
                          .interview.competencies"
                        :key="index"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-circle-small</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            competence.name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col col-6">
                <v-row class="my-0 mx-6">
                  <v-col>
                    <div class="center-content">
                      <video
                        ref="localVideo"
                        id="localVideo"
                        class="call-player-video"
                        poster="img/logo-virtualProfiler-girado.png"
                        autoplay
                        muted
                      ></video>
                    </div>
                  </v-col>
                  <v-col class="col col-12 center-content">
                    <v-tooltip top v-if="microActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(false)">
                          <v-icon size="20" color="primary"
                            >mdi-microphone</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Deactivate microphone</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(true)">
                          <v-icon size="20" color="primary"
                            >mdi-microphone-off</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Activate microphone</span>
                    </v-tooltip>
                    <v-tooltip top v-if="cameraActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(false)">
                          <v-icon size="20" color="primary">mdi-video</v-icon>
                        </v-btn>
                      </template>
                      <span>Deactivate camera</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(true)">
                          <v-icon size="20" color="primary"
                            >mdi-video-off</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Activate camera</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <!--<v-row>
                  <div>
                    <label for="camera">Select camera</label>
                    <select
                      v-model="selectedCamera"
                      id="camera"
                      @change="handleCameraChange"
                    >
                      <option
                        v-for="device in videoDevices"
                        :key="device.deviceId"
                        :value="device.deviceId"
                      >
                        {{ device.label || `Camera ${device.deviceId}` }}
                      </option>
                    </select>
                  </div>

                  <div>
                    <label for="microphone">Microphone</label>
                    <select v-model="selectedMicrophone" id="microphone">
                      <option
                        v-for="device in audioDevices"
                        :key="device.deviceId"
                        :value="device.deviceId"
                      >
                        {{ device.label || `Microphone ${device.deviceId}` }}
                      </option>
                    </select>
                  </div>

                  <div>
                    <label for="speaker">Speakers</label>
                    <select v-model="selectedSpeaker" id="speaker">
                      <option
                        v-for="device in outputDevices"
                        :key="device.deviceId"
                        :value="device.deviceId"
                      >
                        {{ device.label || `Speakers ${device.deviceId}` }}
                      </option>
                    </select>
                  </div>
                </v-row> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col col-12 d-flex justify-center">
                <v-btn class="ok-modal" @click="createAndEnterInterview">
                  Create and enter
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CandidatesDataService from "@/services/CandidatesDataService";
import SocketioService from "@/services/socketioService.js";
import UserMediaController from "@/services/UserMedia.js";

export default {
  name: "WaitingRoom",
  data() {
    return {
      candidateName: "",
      candidates: [],
      microActivated: true,
      cameraActivated: true,
      selfUserStream: null,
      // videoDevices: [],
      // audioDevices: [],
      // outputDevices: [],
      // selectedCamera: null,
      // selectedMicrophone: null,
      // selectedSpeaker: null,
    };
  },
  created() {
    SocketioService.emisor.$on("callRoomResponse", async (data) => {
      this.$socket.callRoom = data.callId;
    });
  },
  async mounted() {
    console.log("this.$route.params: ", this.$route.params);

    if (this.$route.params.interview === undefined)
      this.$router.push("/candidates");
    else if (this.$route.params.interview.candidateId !== undefined) {
      localStorage.removeItem("isCandidate");
      localStorage.removeItem("query");
      await this.getAllCandidates();
      // await this.getDevices();
    }

    UserMediaController.getUserMedia(
      function (stream) {
        if (!stream) {
          stream = UserMediaController.getPlaceholderStream();
        }

        const localVideo = document.getElementById("localVideo");
        localVideo.srcObject = new MediaStream(stream.getVideoTracks());

        // Apply options to stream
        UserMediaController.setMediaStreamVideoEnabled(
          stream,
          this.cameraActivated
        );
        UserMediaController.setMediaStreamAudioEnabled(
          stream,
          this.microActivated
        );

        // Get the stream
        this.selfUserStream = stream;
      }.bind(this)
    );
  },
  beforeRouteLeave(to, from, next) {
    if (this.selfUserStream)
      UserMediaController.stopStream(this.selfUserStream);
    this.selfUserStream = null;
    next();
  },
  methods: {
    async getAllCandidates() {
      CandidatesDataService.findAll().then(
        (response) => {
          if (response.status == 200) {
            this.candidates = response.data.data;

            this.candidateName = this.candidates.find(
              (candidate) =>
                candidate.id == this.$route.params.interview.candidateId
            ).username;
          } else {
            this.successfullCandidates = false;
            this.messageCandidates =
              "An error has occurred retrieving the candidate info. Please, try again later.";
          }
        },
        (error) => {
          this.successfullCandidates = false;
          this.messageCandidates =
            "An error has occurred retrieving the candidate info. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        }
      );
    },
    /* async getDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      this.audioDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      this.outputDevices = devices.filter(
        (device) => device.kind === "audiooutput"
      );
    },
    async setCameraStream(deviceId) {
      // Detén cualquier flujo de medios existente
      if (this.selfUserStream) {
        this.selfUserStream.getTracks().forEach((track) => track.stop());
      }

      // Configura el flujo de medios para la cámara seleccionada
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: deviceId } },
        });
        this.selfUserStream = stream;

        // Asigna el flujo al elemento <video>
        const videoElement = this.$refs.localVideo;
        videoElement.srcObject = stream;
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    },
    async handleCameraChange() {
      // Cambia la fuente del video al cambiar de cámara
      await this.setCameraStream(this.selectedCamera);
    }, */
    enableAudio(enabled) {
      this.microActivated = enabled;
      UserMediaController.setMediaStreamAudioEnabled(
        this.selfUserStream,
        enabled
      );
    },
    enableVideo(enabled) {
      this.cameraActivated = enabled;
      UserMediaController.setMediaStreamVideoEnabled(
        this.selfUserStream,
        enabled
      );
    },
    createAndEnterInterview() {
      console.log("createAndEnterInterview");
      this.$router.push({
        name: "interview",
        params: {
          interview: this.$route.params.interview,
          video: this.cameraActivated,
          audio: this.microActivated,
          interviewerName: "Interviewer",
          candidateName: this.candidateName,
          isCandidate: false,
        },
      });
    },
  },
};
</script>
