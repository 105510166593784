<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row justify="center" align="center">
          <v-col cols="12" class="d-flex flex-column align-center text-center">
            <v-img
              contain
              src="img/logo-virtualProfiler.png"
              class="logo-class"
            ></v-img>

            <v-card width="600" class="mt-12">
              <v-card-title class="text-h5 justify-center">
                Reset password
              </v-card-title>

              <v-form
                v-model="valid"
                @submit.prevent="resetPassword"
                v-if="
                  token !== null &&
                  token !== undefined &&
                  token !== 'null' &&
                  token !== 'undefined'
                "
              >
                <v-card-text class="py-0">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    :rules="[passwordRules.required, passwordRules.minLength]"
                    outlined
                    dense
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="togglePasswordVisibility"
                  ></v-text-field>
                  <v-text-field
                    v-model="confirmPassword"
                    label="Repeat password"
                    :rules="[
                      confirmPasswordRules.required,
                      confirmPasswordRules.passwordMatch,
                    ]"
                    outlined
                    dense
                    :type="showConfirmPassword ? 'text' : 'password'"
                    :append-icon="
                      showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'
                    "
                    @click:append="toggleConfirmPasswordVisibility"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    v-if="!resettingPassword"
                    :disabled="!isValid"
                  >
                    Reset
                  </v-btn>
                  <v-btn color="primary" block type="submit" v-else disabled>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
              </v-form>
              <v-alert
                v-else
                outlined
                :color="'error'"
                :icon="'mdi-alert'"
                class="mx-2"
              >
                Invalid user credentials, please make sure they are correct.
              </v-alert>

              <v-alert
                v-if="messageReset"
                outlined
                :color="successfullReset ? 'success' : 'error'"
                :icon="successfullReset ? 'mdi-check-circle' : 'mdi-alert'"
                dismissible
                class="mx-2"
              >
                {{ messageReset }}
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AuthDataService from "@/services/AuthDataService";

export default {
  name: "ResetPassword",
  data() {
    return {
      token: null,
      password: "",
      confirmPassword: "",
      valid: false,
      passwordRules: {
        required: (v) => !!v || "Required field",
        minLength: (v) => v.length > 7 || "Min. length 8 characters",
      },
      confirmPasswordRules: {
        required: (v) => !!v || "Required field",
        passwordMatch: (v) => v === this.password || "Passwords do not match",
      },
      messageReset: "",
      successfullReset: false,
      resettingPassword: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    isValid() {
      return (
        this.password !== "" &&
        this.confirmPassword !== "" &&
        this.passwordRules.minLength(this.password) !==
          "Min. length 8 characters" &&
        this.confirmPasswordRules.passwordMatch(this.confirmPassword) !==
          "Passwords do not match"
      );
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    console.log("this.token: ", this.token);
  },
  methods: {
    resetPassword() {
      console.log("reset password");
      this.messageReset = "";

      this.resettingPassword = true;

      AuthDataService.resetPassword(this.password, this.token).then(
        (response) => {
          console.log("response reset password: ", response);
          if (response.status == 204) {
            console.log("password reset");
            this.successfullReset = true;
            this.messageReset =
              "Password successfully reset. You can now log in with your new password.";
            this.password = "";
            this.confirmPassword = "";
          } else {
            this.successfullReset = false;
            this.messageReset =
              "An error occurred while resetting password. Please, try again later.";
          }

          this.resettingPassword = false;
        },
        (error) => {
          this.successfullReset = false;
          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response && error.response.status == 401) {
            if (error.response.data.error == "INVALID_TOKEN") {
              this.messageReset =
                "Invalid token. Please, check if the token is correct.";
            } else if (error.response.data.error == "TOKEN_EXPIRED") {
              this.messageReset =
                "Token expired. Please, generate another one to reset your password.";
            } else {
              this.messageReset =
                "An error occurred while resseting password. Please, try again later.";
            }
          } else if (error.response && error.response.status == 404) {
            this.messageReset =
              "User not found. Please, check if the token is correct.";
          } else if (error.response && error.response.status == 400) {
            if (error.response.data.error == "SAME_PASSWORD") {
              this.messageReset =
                "Password is already in use. Please, use a new one.";
            } else {
              this.messageReset =
                "An error occurred while resetting password. Please, try again later.";
            }
          } else {
            this.messageReset =
              "An error occurred while resetting password. Please, try again later.";
          }

          this.resettingPassword = false;
        }
      );
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  },
};
</script>

<style scoped>
.v-card {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.v-alert {
  margin-top: 10px;
}

/* Ajustar la imagen */
.logo-class {
  width: 20% !important;
}
</style>
