<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-5 pa-4">
                <v-row>
                  <v-col class="col col-12">
                    <h6>Interviewer:</h6>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col col-12">
                    <div>
                      <video
                        ref="interviewerVideo"
                        id="interviewerVideo"
                        class="call-player-video"
                        poster="img/logo-virtualProfiler-girado.png"
                        autoplay
                      ></video>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="!isCandidate">
                  <v-col class="col col-12 d-flex justify-center">
                    <v-tooltip top v-if="microActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(false)">
                          <v-icon size="20" color="primary"
                            >mdi-microphone</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Deactivate microphone</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(true)">
                          <v-icon size="20" color="primary"
                            >mdi-microphone-off</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Activate microphone</span>
                    </v-tooltip>
                    <v-tooltip top v-if="cameraActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(false)">
                          <v-icon size="20" color="primary">mdi-video</v-icon>
                        </v-btn>
                      </template>
                      <span>Deactivate camera</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(true)">
                          <v-icon size="20" color="primary"
                            >mdi-video-off</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Activate camera</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="col col-12 d-flex justify-center">
                    <v-btn v-if="remoteMicro" icon disabled>
                      <v-icon size="20" color="primary">mdi-microphone</v-icon>
                    </v-btn>
                    <v-btn v-else icon disabled>
                      <v-icon size="20" color="primary"
                        >mdi-microphone-off</v-icon
                      >
                    </v-btn>
                    <v-btn v-if="remoteCamera" icon disabled>
                      <v-icon size="20" color="primary">mdi-video</v-icon>
                    </v-btn>
                    <v-btn v-else icon disabled>
                      <v-icon size="20" color="primary">mdi-video-off</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col col-5 pa-4">
                <v-row>
                  <v-col class="col col-12">
                    <h6>Candidate: {{ candidateName }}</h6>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col col-12">
                    <div>
                      <video
                        ref="candidateVideo"
                        id="candidateVideo"
                        class="call-player-video"
                        poster="img/logo-virtualProfiler-girado.png"
                        autoplay
                      ></video>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="isCandidate">
                  <v-col class="col col-12 d-flex justify-center">
                    <v-tooltip top v-if="microActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(false)">
                          <v-icon size="20" color="primary"
                            >mdi-microphone</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Deactivate microphone</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableAudio(true)">
                          <v-icon size="20" color="primary"
                            >mdi-microphone-off</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Activate microphone</span>
                    </v-tooltip>
                    <v-tooltip top v-if="cameraActivated">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(false)">
                          <v-icon size="20" color="primary">mdi-video</v-icon>
                        </v-btn>
                      </template>
                      <span>Deactivate camera</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="enableVideo(true)">
                          <v-icon size="20" color="primary"
                            >mdi-video-off</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Activate camera</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col class="col col-12 d-flex justify-center">
                    <v-btn v-if="remoteMicro" icon disabled>
                      <v-icon size="20" color="primary">mdi-microphone</v-icon>
                    </v-btn>
                    <v-btn v-else icon disabled>
                      <v-icon size="20" color="primary"
                        >mdi-microphone-off</v-icon
                      >
                    </v-btn>
                    <v-btn v-if="remoteCamera" icon disabled>
                      <v-icon size="20" color="primary">mdi-video</v-icon>
                    </v-btn>
                    <v-btn v-else icon disabled>
                      <v-icon size="20" color="primary">mdi-video-off</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="col col-2 py-4 px-2">
                <v-row>
                  <v-col class="col col-12">
                    <h6>Chat</h6>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col col-12" v-if="messagesLoaded">
                    <v-card
                      v-if="chatMessages.length > 0"
                      class="overflow-y-auto overflow-x-hidden chat-card mb-4 pa-2 mr-2"
                      id="scrollContainer"
                    >
                      <v-row
                        v-for="(message, index) in chatMessages"
                        :key="index"
                      >
                        <v-col class="col col-12 pb-0">
                          <p class="mb-0">
                            <b>{{ message.user }}:</b> {{ message.message }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card v-else class="chat-card mb-4 pa-2 mr-2">
                      <v-row>
                        <v-col cols="12">
                          <v-card-text>
                            <p class="information-error">No messages yet.</p>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-form @submit.prevent="sendMessage">
                      <v-row>
                        <v-col class="col col-10 pr-0 pb-0">
                          <v-text-field
                            class="pt-0"
                            label="Mensaje"
                            v-model="message"
                            autofocus
                          ></v-text-field>
                        </v-col>
                        <v-col class="col col-2 pl-0">
                          <v-btn
                            ref="sendMessageBtn"
                            icon
                            type="submit"
                            :disabled="message.length > 0 ? false : true"
                          >
                            <v-icon size="20" color="primary">mdi-send</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col class="col col-12" v-else>
                    <v-card
                      class="overflow-y-auto overflow-x-hidden chat-card mb-4 pa-2 mr-2 center-content"
                      id="scrollContainer"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        class="mt-4"
                      ></v-progress-circular>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="col col-12 d-flex justify-center">
                <v-btn class="error-btn" @click="hangUpCallDialog = true">
                  <v-icon>mdi-phone-hangup</v-icon>&nbsp;&nbsp;Hang up call
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-snackbar v-model="alertLeave" :timeout="2000">
          <p v-if="isCandidate" class="mb-0 snackbar">
            {{ interviewerName }} has left the call.
          </p>
          <p v-else class="mb-0 snackbar">
            {{ candidateName }} has left the call.
          </p>
        </v-snackbar>
        <v-snackbar v-model="alertErrorLeave" :timeout="2000">
          <p class="mb-0 snackbar-error">
            An error occurred while leaving the call.
          </p>
        </v-snackbar>
        <v-dialog v-model="hangUpCallDialog" max-width="550" min-height="550">
          <v-card>
            <v-card-title>
              <h5>Leaving the video interview</h5>
            </v-card-title>
            <v-card-text v-if="isCandidate">
              <p>How was the videointerview experience?</p>
              <v-row>
                <v-col cols="12" class="center-content">
                  <v-icon
                    size="80"
                    :color="colors.bad"
                    @click="opinion = 'bad'"
                    class="mr-10"
                    >mdi-emoticon-sad</v-icon
                  >
                  <v-icon
                    size="80"
                    :color="colors.normal"
                    @click="opinion = 'normal'"
                    class="mr-10"
                    >mdi-emoticon-neutral</v-icon
                  >
                  <v-icon
                    size="80"
                    :color="colors.good"
                    @click="opinion = 'good'"
                    >mdi-emoticon</v-icon
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Comment (Optional)"
                    v-model="comment"
                  ></v-textarea
                ></v-col>
              </v-row>
              <v-alert
                v-if="!successfullSendFeedback"
                outlined
                :color="'error'"
                :icon="'mdi-alert'"
                class="mx-2"
              >
                An error has occurred sending the interview feedback. Please,
                try again later.
              </v-alert>
            </v-card-text>
            <v-card-text v-else>
              <p>Do you really want to leave the video interview?</p>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="hangUpCallDialog = false"
                class="cancel-modal"
              >
                Cancel
              </v-btn>
              <v-btn
                @click="closeCall"
                class="ok-modal"
                :disabled="isCandidate && opinion == null"
              >
                Exit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="callFinishedDialog"
          persistent
          max-width="550"
          min-height="550"
        >
          <v-card>
            <v-card-title>
              <h5>The video interview has been completed by host</h5>
            </v-card-title>
            <v-card-text>
              <p>
                The session will now come to an end, we hope you enjoyed it!
              </p>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn @click="redirect" class="ok-modal"> Accept </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import SocketioService from "@/services/socketioService.js";
import StreamVolumeAnalyzer from "@/services/StreamVolumeAnalyzer";
import UserMediaController from "@/services/UserMedia.js";
import VideosDataService from "@/services/VideosDataService.js";
import { Peer } from "peerjs";
import utils from "@/utils/utils";
import InterviewsDataService from "@/services/InterviewsDataService";

export default {
  name: "Interview",
  data() {
    return {
      interview: {},
      microActivated: true,
      cameraActivated: true,
      interviewerName: "",
      candidateName: "",
      isCandidate: false,
      joinedCall: false,
      rejoinTimeout: null,
      peer: null,
      peerId: "",
      callUsers: {},
      pendingCalls: {},
      selfId: "",
      selfUserStream: null,
      otherStream: null,
      selfStreamAnalyzer: null,
      joining: false,
      closed: true,
      candidateVideo: null,
      interviewerVideo: null,
      chatMessages: [],
      message: "",
      alertLeave: false,
      alertErrorLeave: false,
      hangUpCallDialog: false,
      callFinishedDialog: false,
      redirectParams: {},
      remoteMicro: false,
      remoteCamera: false,
      recorder: {
        interviewerVideo: null,
        interviewerAudio: null,
        candidateVideo: null,
        candidateAudio: null,
      },
      messagesLoaded: true,
      opinion: null,
      comment: "",
      selectedGood: false,
      selectedNormal: false,
      selectedBad: false,
      colors: {
        good: "green",
        normal: "yellow",
        bad: "red",
      },
      successfullSendFeedback: true,
      candidateInside: false,
      candidateId: null,
    };
  },
  watch: {
    opinion() {
      if (this.opinion === "good") {
        this.selectedGood = true;
        this.selectedNormal = false;
        this.selectedBad = false;
        this.colors.good = "#388E3C";
        this.colors.normal = "#FFF59D";
        this.colors.bad = "#EF9A9A";
      } else if (this.opinion === "normal") {
        this.selectedGood = false;
        this.selectedNormal = true;
        this.selectedBad = false;
        this.colors.good = "#A5D6A7";
        this.colors.normal = "#FBC02D";
        this.colors.bad = "#EF9A9A";
      } else if (this.opinion === "bad") {
        this.selectedGood = false;
        this.selectedNormal = false;
        this.selectedBad = true;
        this.colors.good = "#A5D6A7";
        this.colors.normal = "#FFF59D";
        this.colors.bad = "#D32F2F";
      }
    },
  },
  created() {
    SocketioService.emisor.$on("callCloseResponse", async (data) => {
      if (!this.joinedCall) return;
      switch (data.reason) {
        case "LEFT":
          break;
        case "ALREADY_IN_CALL":
          this.rejoinCall();
          break;
        case "CALL_CLOSED":
          this.joinedCall = false;
          this.closed = true;
          this.destroyPrevCallData();
          break;
      }
    });

    SocketioService.emisor.$on("callOpenResponse", (data) => {
      if (!this.joinedCall || data.call !== this.$socket.callRoom) {
        return;
      }

      this.selfId = data.user_id;

      // Analize the input stream
      if (this.selfStreamAnalyzer) {
        this.selfStreamAnalyzer.destroy();
      }
      this.selfStreamAnalyzer = new StreamVolumeAnalyzer(
        data.user_id,
        this.selfUserStream,
        this.onVolume.bind(this)
      );
      this.selfStreamAnalyzer.start();
    });

    SocketioService.emisor.$on("callUserOptResponse", (data) => {
      this.remoteMicro = data.audio;
      this.remoteCamera = data.video;
    });

    SocketioService.emisor.$on("callUserJoinResponse", async (data) => {
      console.log("response callUserJoinResponse", data);
      this.remoteMicro = data.audio;
      this.remoteCamera = data.video;
      this.candidateInside = true;

      if (!this.joinedCall || data.call !== this.$socket.callRoom) {
        return;
      }

      const uid = data.user_id;
      if (this.selfId === uid) {
        return;
      }

      if (this.callUsers[uid]) {
        return;
      } // Already joined // Should not happen

      this.callUsers[uid] = {
        id: uid,
        stream: null,
        call: null,
        peerId: data.peer_id,
        muted: !!data.muted,
        audio: !!data.audio,
        video: !!data.video,
        hand: !!data.hand,
        userInfo: {
          name: data.user_name,
          image: data.user_image,
        },
        flags: data.flags,
        serializations: data.serializations,
      };

      // Call
      if (this.peerId < this.callUsers[uid].peerId) {
        // We must call the other peer, the other peer must answer
        this.callPeer(uid, this.callUsers[uid].peerId);
      } else if (this.pendingCalls[this.callUsers[uid].peerId]) {
        this.onCall(this.pendingCalls[this.callUsers[uid].peerId]);
        this.pendingCalls[this.callUsers[uid].peerId] = null;
      }
    });

    SocketioService.emisor.$on("callUserLeaveResponse", (data) => {
      this.alertLeave = true;

      if (!this.isCandidate) {
        this.candidateVideo = document.getElementById("candidateVideo");
        this.candidateVideo.srcObject = null;
      } else {
        this.interviewerVideo = document.getElementById("interviewerVideo");
        this.interviewerVideo.srcObject = null;
      }

      if (!this.joinedCall || data.call !== this.$socket.callRoom) return;

      const uid = data.user_id;

      if (!this.callUsers[uid]) return;

      if (this.callUsers[uid].call) {
        this.callUsers[uid].call.close();
      }

      if (this.callUsers[uid].analyzer) {
        this.callUsers[uid].analyzer.destroy();
      }

      delete this.callUsers[uid];

      UserMediaController.stopStream(this.otherStream);
    });

    SocketioService.emisor.$on("callUserChatMsgResponse", async (data) => {
      console.log("data.username: ", data.username);
      console.log("this.interview: ", this.interview);
      console.log(
        "this.interview.interviewerId: ",
        this.interview.interviewerId
      );
      if (data.username !== this.interview.interviewerId)
        this.chatMessages.push({
          user: data.message.isCandidate ? message.username : "Interviewer",
          message: data.message.message,
        });

      this.scrollDown();
    });

    SocketioService.emisor.$on("callUserFinishedResponse", (data) => {
      this.callFinishedDialog = true;
    });

    SocketioService.emisor.$on("callMessagesResponse", async (data) => {
      console.log("response callMessagesResponse", data);

      // Retrieve previous chat messages
      if (data.messages && this.chatMessages.length === 0) {
        this.messagesLoaded = false;

        data.messages.forEach((message) => {
          let messageObj = {
            user: message.message.isCandidate
              ? message.username
              : "Interviewer",
            message: message.message.message,
          };
          this.chatMessages.push(messageObj);
        });
        this.messagesLoaded = true;
      } else {
        this.messagesLoaded = true;
      }

      console.log("this.chatMessages: ", this.chatMessages);
    });
  },
  mounted() {
    if (this.$route.params.interview === undefined) {
      if (localStorage.getItem("isCandidate") !== "false") {
        this.$router.push(
          `/waiting-room-candidate?${localStorage.getItem("query")}`
        );
      } else this.$router.push("/candidates");
    } else {
      this.candidateId = this.$route.params.candidateId;
      this.interview = this.$route.params.interview;
      this.cameraActivated = this.$route.params.video;
      this.microActivated = this.$route.params.audio;
      this.interviewerName = this.$route.params.interviewerName;
      this.candidateName = this.$route.params.candidateName;
      this.isCandidate = this.$route.params.isCandidate;
      this.redirectParams = this.$route.params.redirectParams;

      console.log("this.$route.params: ", this.$route.params);

      localStorage.setItem("isCandidate", this.isCandidate.toString());
      const query = new URLSearchParams({ ...this.redirectParams });
      localStorage.setItem("query", query.toString());
    }

    this.destroyPrevCallData();
    this.joining = true;
    this.joinCall(true);

    window.addEventListener("resize", this.scrollDown);
  },
  beforeRouteLeave(to, from, next) {
    if (this.selfUserStream)
      UserMediaController.stopStream(this.selfUserStream);
    this.selfUserStream = null;
    this.destroyPrevCallData();
    next();
  },
  methods: {
    destroyPrevCallData() {
      console.log("destroyPrevCallData");
      this.joinedCall = false;

      if (this.rejoinTimeout) {
        clearTimeout(this.rejoinTimeout);
        this.rejoinTimeout = null;
      }

      // Destroy peer
      if (this.peer) {
        this.peer.destroy();
        this.peer = null;
      }

      // Remove call users
      for (const uid in this.callUsers) {
        if (this.callUsers[uid].analyzer) {
          this.callUsers[uid].analyzer.destroy();
        }
      }
      this.callUsers = {};

      this.selfId = "";

      if (this.selfUserStream) {
        UserMediaController.stopStream(this.selfUserStream);
        this.selfUserStream = null;
      }
      if (this.selfStreamAnalyzer) {
        this.selfStreamAnalyzer.destroy();
        this.selfStreamAnalyzer = null;
      }

      this.joining = false;
    },
    createPeerConfig() {
      console.log("createPeerConfig");
      if (!this.$socket.callRoom) {
        console.log("Error, no callRoom");
        this.joining = false;
        alert("Error, no callRoom");
        this.$router.push("/candidates");
        return;
      }

      const peerConfig = {
        host: utils.api.host,
        port: utils.api.port,
        secure: true,
        key: "peerjs",
        config: {
          iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
          sdpSemantics: "unified-plan",
        },
      };

      // Create new peer
      this.peer = new Peer(peerConfig);

      console.log("this.peer: ", this.peer);

      let socketOpen = false;

      this.peer.on(
        "open",
        function (id) {
          console.log("en el peer.on id: ", id);
          this.peerId = id;
          socketOpen = true;

          this.joinedCall = true;

          UserMediaController.getUserMedia(
            function (stream) {
              if (!this.joinedCall) {
                // Left the call in the middle of join
                this.joining = false;
                return "Cannot join the call. en interview";
              }

              if (!stream) {
                stream = UserMediaController.getPlaceholderStream();
              }

              // Apply options to stream
              UserMediaController.setMediaStreamVideoEnabled(
                stream,
                this.cameraActivated
              );
              UserMediaController.setMediaStreamAudioEnabled(
                stream,
                this.microActivated
              );

              // Get the stream
              this.selfUserStream = stream;

              if (this.isCandidate) {
                this.candidateVideo = document.getElementById("candidateVideo");
                this.candidateVideo.srcObject = new MediaStream(
                  this.selfUserStream.getVideoTracks()
                );
              } else {
                this.interviewerVideo =
                  document.getElementById("interviewerVideo");
                this.interviewerVideo.srcObject = new MediaStream(
                  this.selfUserStream.getVideoTracks()
                );
              }

              SocketioService.joinCall(
                this.$socket,
                this.peerId,
                this.cameraActivated,
                this.microActivated
              );

              this.joining = false;

              return id;
            }.bind(this)
          );

          this.peer.on("close", this.onSocketClosed.bind(this));

          this.peer.on("disconnected", this.onSocketDisconnected.bind(this));

          this.peer.on("call", this.onCall.bind(this));

          this.peer.on(
            "error",
            function (err) {
              if (!socketOpen) {
                this.joining = false;
                this.rejoinCall();
                return "";
              }
              console.error(err);
            }.bind(this)
          );
        }.bind(this)
      );

      SocketioService.retrievePreviousMessages(this.$socket);
    },
    onSocketDisconnected() {
      if (this.joinedCall) {
        this.joinedCall = false;
      }
      this.destroyPrevCallData();
      this.rejoinCall();
    },
    onSocketClosed() {
      if (this.joinedCall) {
        this.joinedCall = false;
      }
      this.destroyPrevCallData();
    },
    rejoinCall() {
      if (this.closed) {
        return;
      }
      this.joining = true;
      if (this.rejoinTimeout) {
        clearTimeout(this.rejoinTimeout);
        this.rejoinTimeout = null;
      }
      this.rejoinTimeout = setTimeout(
        function () {
          this.rejoinTimeout = null;
          this.joinCall(false);
        }.bind(this),
        2000
      );
    },
    joinCall(isMounted) {
      console.log("joinCall");
      this.closed = false;

      if (this.joinedCall) {
        this.leaveCall();
      }
      if (!isMounted) this.destroyPrevCallData();

      this.joining = true;

      this.createPeerConfig();
    },
    leaveCall() {
      this.closed = true;
      if (this.joinedCall) {
        SocketioService.leaveCall(this.$socket, true);
      } else {
        this.destroyPrevCallData();
      }
    },
    onCall(call) {
      const pid = call.peer;
      if (this.peerId <= pid) return; // My pid must be greater for me to accept
      const uid = this.findUserByPeer(pid);
      if (!uid) {
        this.pendingCalls[pid] = call;
        return; // Mark as pending for now
      }

      // Accept call
      call.answer(this.selfUserStream);

      this.callUsers[uid].call = call;

      this.callUsers[uid].call.on(
        "stream",
        function (stream) {
          if (this.callUsers[uid]) {
            this.callUsers[uid].stream = stream;
            this.callUsers[uid].analyzer = new StreamVolumeAnalyzer(
              uid,
              stream,
              this.onVolume.bind(this)
            );
            this.callUsers[uid].analyzer.start();

            if (!this.isCandidate) {
              this.recorder.interviewerVideo = RecordRTC(this.selfUserStream, {
                type: "video",
                disableLogs: true,
              });

              this.recorder.interviewerAudio = RecordRTC(this.selfUserStream, {
                type: "audio",
                disableLogs: true,
              });

              this.recorder.candidateVideo = RecordRTC(stream, {
                type: "video",
                disableLogs: true,
              });

              this.recorder.candidateAudio = RecordRTC(stream, {
                type: "audio",
                disableLogs: true,
              });

              this.candidateVideo = document.getElementById("candidateVideo");
              this.candidateVideo.srcObject = stream;

              this.recorder.candidateAudio.startRecording();
              this.recorder.candidateAudio.camera = stream;

              this.recorder.candidateVideo.startRecording();
              this.recorder.candidateVideo.camera = stream;

              this.recorder.interviewerAudio.startRecording();
              this.recorder.interviewerAudio.camera = this.selfUserStream;

              this.recorder.interviewerVideo.startRecording();
              this.recorder.interviewerVideo.camera = this.selfUserStream;
            } else {
              this.interviewerVideo =
                document.getElementById("interviewerVideo");
              this.interviewerVideo.srcObject = stream;
            }

            this.otherStream = stream;
          }
        }.bind(this)
      );
      this.callUsers[uid].call.on("error", function (err) {
        console.error(err);
      });
    },
    findUserByPeer(pid) {
      for (const uid in this.callUsers) {
        if (this.callUsers[uid].peerId === pid) {
          return uid;
        }
      }
      return null;
    },
    onVolume(uid, volume) {
      // No hace nada realmente
    },
    enableAudio(enabled) {
      this.microActivated = enabled;

      if (this.joinedCall) {
        UserMediaController.setMediaStreamAudioEnabled(
          this.selfUserStream,
          enabled
        );
        SocketioService.callOptions(
          this.$socket,
          this.cameraActivated,
          this.microActivated
        );
      }
    },
    enableVideo(enabled) {
      this.cameraActivated = enabled;

      if (this.joinedCall) {
        UserMediaController.setMediaStreamVideoEnabled(
          this.selfUserStream,
          enabled
        );
        SocketioService.callOptions(
          this.$socket,
          this.cameraActivated,
          this.microActivated
        );
      }
    },
    callPeer(uid, peerId) {
      const options = {
        constraints: {
          mandatory: {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true,
          },
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 1,
        },
      };
      this.callUsers[uid].call = this.peer.call(
        peerId,
        this.selfUserStream,
        options
      );

      this.callUsers[uid].call.on(
        "stream",
        function (stream) {
          if (this.callUsers[uid]) {
            this.callUsers[uid].stream = stream;
            this.callUsers[uid].analyzer = new StreamVolumeAnalyzer(
              uid,
              stream,
              this.onVolume.bind(this)
            );
            this.callUsers[uid].analyzer.start();

            if (!this.isCandidate) {
              this.recorder.interviewerVideo = RecordRTC(this.selfUserStream, {
                type: "video",
                disableLogs: true,
              });

              this.recorder.interviewerAudio = RecordRTC(this.selfUserStream, {
                type: "audio",
                disableLogs: true,
              });

              this.recorder.candidateVideo = RecordRTC(stream, {
                type: "video",
                disableLogs: true,
              });

              this.recorder.candidateAudio = RecordRTC(stream, {
                type: "audio",
                disableLogs: true,
              });

              this.candidateVideo = document.getElementById("candidateVideo");
              this.candidateVideo.srcObject = stream;

              this.recorder.candidateAudio.startRecording();
              this.recorder.candidateAudio.camera = stream;

              this.recorder.candidateVideo.startRecording();
              this.recorder.candidateVideo.camera = stream;

              this.recorder.interviewerAudio.startRecording();
              this.recorder.interviewerAudio.camera = this.selfUserStream;

              this.recorder.interviewerVideo.startRecording();
              this.recorder.interviewerVideo.camera = this.selfUserStream;
            } else {
              this.interviewerVideo =
                document.getElementById("interviewerVideo");
              this.interviewerVideo.srcObject = stream;
            }

            this.otherStream = stream;
          }
        }.bind(this)
      );
      this.callUsers[uid].call.on("error", function (err) {
        console.error(err);
      });
    },

    closeCall() {
      this.successfullSendFeedback = true;
      SocketioService.leaveCall(this.$socket, this.isCandidate);
      if (this.isCandidate) {
        InterviewsDataService.sendFeedback(
          this.opinion,
          this.comment,
          this.candidateId,
          this.interview.internalId
        ).then(
          (response) => {
            if (response.status == 200) {
              const query = new URLSearchParams({ ...this.redirectParams });
              this.$router.push(`/waiting-room-candidate?${query.toString()}`);
            } else {
              this.successfullSendFeedback = false;
            }
          },
          (error) => {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullSendFeedback = false;
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );
          }
        );
      } else {
        this.stopRecording();
      }
    },
    redirect() {
      const query = new URLSearchParams({ ...this.redirectParams });
      SocketioService.leaveCall(this.$socket, this.isCandidate);
      this.$router.push(`/waiting-room-candidate?${query.toString()}`);
    },
    sendMessage() {
      const data = {
        message: this.message,
        timeSended: new Date(),
      };

      SocketioService.chatMessage(this.$socket, data);

      this.chatMessages.push({ user: "Tú", message: this.message });
      this.message = "";
      new Promise((resolve) => setTimeout(resolve, 2000));
      this.scrollDown();
    },
    scrollDown() {
      this.$nextTick(() => {
        var element = document.getElementById("scrollContainer");
        element.scrollTop = element.scrollHeight;
      });
    },
    stopRecording() {
      const promises = [];

      if (this.recorder.interviewerAudio !== null) {
        promises.push(
          new Promise((resolve, reject) => {
            this.recorder.interviewerAudio.stopRecording(() => {
              try {
                const interviewerAudioFile =
                  this.recorder.interviewerAudio.getBlob();

                this.recorder.interviewerAudio.camera.stop();
                this.recorder.interviewerAudio.destroy();
                this.recorder.interviewerAudio = null;

                return resolve({
                  blob: interviewerAudioFile,
                  type: "audio",
                  source: "interviewer",
                });
              } catch (error) {
                return reject(error);
              }
            });
          })
        );
      }

      if (this.recorder.interviewerVideo !== null) {
        promises.push(
          new Promise((resolve, reject) => {
            this.recorder.interviewerVideo.stopRecording(() => {
              try {
                const interviewerVideoFile =
                  this.recorder.interviewerVideo.getBlob();

                this.recorder.interviewerVideo.camera.stop();
                this.recorder.interviewerVideo.destroy();
                this.recorder.interviewerVideo = null;

                return resolve({
                  blob: interviewerVideoFile,
                  type: "video",
                  source: "interviewer",
                });
              } catch (error) {
                return reject(error);
              }
            });
          })
        );
      }

      if (this.recorder.candidateAudio !== null) {
        promises.push(
          new Promise((resolve, reject) => {
            this.recorder.candidateAudio.stopRecording(() => {
              try {
                const candidateAudioFile =
                  this.recorder.candidateAudio.getBlob();

                this.recorder.candidateAudio.camera.stop();
                this.recorder.candidateAudio.destroy();
                this.recorder.candidateAudio = null;

                return resolve({
                  blob: candidateAudioFile,
                  type: "audio",
                  source: "candidate",
                });
              } catch (error) {
                return reject(error);
              }
            });
          })
        );
      }

      if (this.recorder.candidateVideo !== null) {
        promises.push(
          new Promise((resolve, reject) => {
            this.recorder.candidateVideo.stopRecording(() => {
              try {
                const candidateVideoFile =
                  this.recorder.candidateVideo.getBlob();

                this.recorder.candidateVideo.camera.stop();
                this.recorder.candidateVideo.destroy();
                this.recorder.candidateVideo = null;

                return resolve({
                  blob: candidateVideoFile,
                  type: "video",
                  source: "candidate",
                });
              } catch (error) {
                return reject(error);
              }
            });
          })
        );
      }

      console.log("antes de uploadVideo");

      Promise.all(promises)
        .then((promise) => {
          if (this.candidateInside) {
            VideosDataService.uploadVideo(
              promise,
              this.$socket.id,
              this.$socket.callRoom,
              this.interview.candidateId
            )
              .then((res) => {
                console.log("res de uploadVideo: ", res);
                if (res.status == 200) this.$router.push("/candidates");
                else this.alertLeave = true;
              })
              .catch((err) => {
                console.error(err);
                this.alertLeave = true;
              });
          } else {
            this.$router.push("/candidates");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
