<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Candidates</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openUploadCVs"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Upload CVs
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageDeleteCandidate"
                  outlined
                  :color="successfullDeleteCandidate ? 'success' : 'error'"
                  :icon="
                    successfullDeleteCandidate
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteCandidate }}
                </v-alert>
                <v-alert
                  v-if="messageCreateInterview"
                  outlined
                  :color="successfullCreateInterview ? 'success' : 'error'"
                  :icon="
                    successfullCreateInterview
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateInterview }}
                </v-alert>

                <v-alert
                  v-if="messageEditInterview"
                  outlined
                  :color="successfullEditInterview ? 'success' : 'error'"
                  :icon="
                    successfullEditInterview ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditInterview }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteInterview"
                  outlined
                  :color="successfullDeleteInterview ? 'success' : 'error'"
                  :icon="
                    successfullDeleteInterview
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteInterview }}
                </v-alert>

                <v-alert
                  v-if="messageResendMail"
                  outlined
                  :color="successfullResendMail ? 'success' : 'error'"
                  :icon="
                    successfullResendMail ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageResendMail }}
                </v-alert>

                <v-data-table
                  :headers="headers"
                  :items="candidates"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait"
                  multi-sort
                  show-expand
                  single-expand
                  :expanded.sync="expanded"
                >
                  <template v-slot:[`item.cvExtractionStatus`]="{ item }">
                    <v-chip :color="getColor(item.cvExtractionStatus)">
                      {{ item.cvExtractionStatus }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-4">
                      <v-card-title class="pt-0">
                        <h4>Videointerviews</h4>
                      </v-card-title>
                      <v-data-table
                        :headers="headersInterviews"
                        :items="item.interviews"
                        multi-sort
                        :options="{ itemsPerPage: 5 }"
                        v-if="item.interviews.length > 0"
                      >
                        <template v-slot:[`item.name`]="{ item }">
                          <v-card-text class="d-flex align-center">
                            {{ item.name | slice40Characters }}
                            <v-tooltip top v-if="item.name.length >= 40">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  class="ml-2 more-info-btn"
                                  :ripple="false"
                                >
                                  <v-icon size="20" color="primary"
                                    >mdi-information</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>{{ item.name }}</span>
                            </v-tooltip>
                          </v-card-text>
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                          <v-card-text
                            class="d-flex align-center"
                            v-if="item.description !== null"
                          >
                            {{ item.description | slice20Characters }}
                            <v-tooltip top v-if="item.description.length >= 20">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  class="ml-2 more-info-btn"
                                  :ripple="false"
                                >
                                  <v-icon size="20" color="primary"
                                    >mdi-information</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>{{ item.description }}</span>
                            </v-tooltip>
                          </v-card-text>

                          <v-card-text class="d-flex align-center" v-else>
                            -
                          </v-card-text>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          {{ item.date | formatDate }}
                        </template>
                        <template v-slot:[`item.position`]="{ item }">
                          {{ item.position.name }}
                        </template>
                        <template v-slot:[`item.competencies`]="{ item }">
                          <v-card-text class="d-flex align-center">
                            {{ item.competencies.length }}
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  class="ml-2 more-info-btn"
                                  :ripple="false"
                                >
                                  <v-icon size="20" color="primary"
                                    >mdi-information</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>
                                <v-list color="transparent">
                                  <v-list-item
                                    v-for="(
                                      competence, index
                                    ) in item.competencies"
                                    :key="index"
                                  >
                                    <v-list-item-icon>
                                      <v-icon class="white--text"
                                        >mdi-circle-small</v-icon
                                      >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title class="white--text">{{
                                        competence.name
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </span>
                            </v-tooltip>
                          </v-card-text>
                        </template>
                        <template v-slot:[`item.isIaInterviewer`]="{ item }">
                          <v-card-text
                            v-if="item.isIaInterviewer"
                            class="d-flex align-end"
                            ><v-icon size="20" color="primary">mdi-robot</v-icon
                            >&nbsp;&nbsp;Avatar</v-card-text
                          >
                          <v-card-text v-else class="d-flex align-end"
                            ><v-icon size="20" color="primary"
                              >mdi-account</v-icon
                            >&nbsp;&nbsp;Human Interviewer</v-card-text
                          >
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                          <v-chip :color="getColorInterviewStatus(item.status)">
                            {{ item.status }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                @click="resendInterviewMail(item)"
                                class="mr-2"
                                :disabled="item.status !== 'CREATED'"
                              >
                                <v-icon size="20" color="primary"
                                  >mdi-send</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Resend email</span>
                          </v-tooltip>

                          <v-tooltip top v-if="!item.isIaInterviewer">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                @click="openAccessInterview(item)"
                                class="mr-2"
                                :disabled="item.status !== 'CREATED'"
                              >
                                <v-icon size="20" color="primary"
                                  >mdi-play</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Enter</span>
                          </v-tooltip>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                @click="openEditInterview(item)"
                                class="mr-2"
                                :disabled="item.status !== 'CREATED'"
                              >
                                <v-icon size="20" color="primary"
                                  >mdi-square-edit-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                @click="openDeleteInterview(item)"
                              >
                                <v-icon size="20" color="error"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                @click="showAnalysis(item)"
                                color="primary"
                                :disabled="item.status !== 'ANALYSED'"
                              >
                                <v-icon size="20">mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span>Show analysis</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                      <v-alert
                        v-else-if="
                          item.interviews.length === 0 && !messageInterviews
                        "
                        outlined
                        type="info"
                        color="info"
                        class="mb-0"
                      >
                        The candidate has no scheduled videointerviews.
                      </v-alert>

                      <v-alert
                        class="mt-4"
                        v-if="messageInterviews"
                        outlined
                        :color="successfullInterviews ? 'success' : 'error'"
                        :icon="
                          successfullInterviews
                            ? 'mdi-check-circle'
                            : 'mdi-alert'
                        "
                        dismissible
                      >
                        {{ messageInterviews }}
                      </v-alert>
                    </td>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openCV(item)"
                          :disabled="item.cvExtractionStatus !== 'COMPLETED'"
                        >
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openCreateInterview(item)"
                          color="primary"
                        >
                          <v-icon size="20">mdi-video-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Create Videointerview</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openDeleteCandidate(item)"
                        >
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Candidate</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageCandidates"
              outlined
              :color="successfullCandidates ? 'success' : 'error'"
              :icon="successfullCandidates ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageCandidates }}
            </v-alert>
            <v-alert
              class="mt-4"
              v-if="messagePositions"
              outlined
              :color="successfullPositions ? 'success' : 'error'"
              :icon="successfullPositions ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messagePositions }}
            </v-alert>
            <v-alert
              class="mt-4"
              v-if="messageAnalysisTypes"
              outlined
              :color="successfullAnalysisTypes ? 'success' : 'error'"
              :icon="
                successfullAnalysisTypes ? 'mdi-check-circle' : 'mdi-alert'
              "
              dismissible
            >
              {{ messageAnalysisTypes }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="deleteCandidateDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete Candidate</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected candidate? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteCandidateDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingCandidate">
            <v-btn @click="deleteCandidate" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="deleteCandidate" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createInterviewDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createInterviewForm">
          <v-card-title>
            <h5>Create videointerview</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Name *"
                    v-model="interview.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="interview.description" :rules="rules">
                    <template v-slot:label>
                      <div>Description *</div>
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="positions"
                    item-text="name"
                    item-value="id"
                    label="Position *"
                    v-model="interview.positionId"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="analysisTypes"
                    item-text="name"
                    item-value="id"
                    label="Analysis Type *"
                    v-model="interview.analysisTypeId"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-radio-group v-model="interview.isIaInterviewer">
                    <template v-slot:label>Interviewer type *</template>
                    <v-radio
                      v-for="(option, index) in optionsInterviewer"
                      :key="index"
                      :label="option.label"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="
                              interview.isIaInterviewer
                                ? 'Maximum date of implementation *'
                                : 'Date *'
                            "
                            readonly
                            v-on="on"
                            v-model="interview.day"
                            :rules="rules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es-es"
                          v-model="interview.day"
                          :min="now"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-menu
                        v-model="menuTime"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="
                              interview.isIaInterviewer
                                ? 'Maximum hour of implementation *'
                                : 'Hour *'
                            "
                            readonly
                            v-on="on"
                            v-model="interview.hour"
                            :rules="rules"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="interview.hour"
                          format="24hr"
                          elevation="15"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageDialog"
                outlined
                :color="successfullDialog ? 'success' : 'error'"
                :icon="successfullDialog ? 'mdi-check-circle' : 'mdi-alert'"
                dismissible
              >
                {{ messageDialog }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateInterview" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn @click="createInterview" class="ok-modal" v-if="!creating">
              Create
            </v-btn>
            <v-btn @click="createInterview" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editInterviewDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editInterviewForm">
          <v-card-title>
            <h5>Edit videointerview</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEditInterview.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="itemToEditInterview.description"
                    :rules="rules"
                  >
                    <template v-slot:label>
                      <div>Description *</div>
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="positions"
                    item-text="name"
                    item-value="id"
                    label="Position *"
                    v-model="itemToEditInterview.positionId"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="analysisTypes"
                    item-text="name"
                    item-value="id"
                    label="Analysis Type *"
                    v-model="itemToEditInterview.analysisTypeId"
                    :rules="rules"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-radio-group v-model="itemToEditInterview.isIaInterviewer">
                    <template v-slot:label>Interviewer type *</template>
                    <v-radio
                      v-for="(option, index) in optionsInterviewer"
                      :key="index"
                      :label="option.label"
                      :value="option.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        v-model="menuDateEdit"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="
                              itemToEditInterview.isIaInterviewer
                                ? 'Maximum date of implementation *'
                                : 'Date *'
                            "
                            readonly
                            v-on="on"
                            v-model="itemToEditInterview.day"
                            :rules="rules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es-es"
                          v-model="itemToEditInterview.day"
                          :min="now"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-menu
                        v-model="menuTimeEdit"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="
                              itemToEditInterview.isIaInterviewer
                                ? 'Maximum hour of implementation *'
                                : 'Hour *'
                            "
                            readonly
                            v-on="on"
                            v-model="itemToEditInterview.hour"
                            :rules="rules"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="itemToEditInterview.hour"
                          format="24hr"
                          elevation="15"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageDialogEditInterview"
                outlined
                :color="successfullDialog ? 'success' : 'error'"
                :icon="successfullDialog ? 'mdi-check-circle' : 'mdi-alert'"
                dismissible
              >
                {{ messageDialogEditInterview }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelEditInterview" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn @click="editInterview" class="ok-modal" v-if="!updating">
              Save changes
            </v-btn>
            <v-btn @click="editInterview" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteInterviewDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete videointerview</h5>
        </v-card-title>
        <v-card-text>
          <p>
            Do you really want to delete the selected video interview?<br />If
            yes, press yes, press Confirm.
          </p>
          <v-textarea v-model="reason" :rules="rules">
            <template v-slot:label>
              <div>Reason *</div>
            </template>
          </v-textarea>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteInterviewDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingInterview">
            <v-btn
              @click="deleteInterview"
              class="ok-modal"
              v-if="reason !== ''"
            >
              Confirm
            </v-btn>
            <v-btn @click="deleteInterview" class="ok-modal" disabled v-else>
              Confirm
            </v-btn>
          </template>
          <v-btn @click="deleteInterview" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="accessInterviewDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Start videointerview</h5>
        </v-card-title>
        <v-card-text>
          <p>
            It is not possible to start the videointerview until ten minutes
            before the scheduled date and time ({{ accessDate }}).
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="accessInterviewDialog = false" class="ok-modal">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CandidatesDataService from "@/services/CandidatesDataService";
import PositionsDataService from "@/services/PositionsDataService";
import InterviewsDataService from "@/services/InterviewsDataService";
import SocketioService from "@/services/socketioService.js";

export default {
  name: "CandidatesList",
  data() {
    return {
      candidates: [],
      successfullCandidates: false,
      messageCandidates: "",
      successfullPositions: false,
      messagePositions: "",
      successfullAnalysisTypes: false,
      messageAnalysisTypes: "",
      successfullInterviews: true,
      messageInterviews: "",
      loading: false,
      successfullDeleteCandidate: false,
      messageDeleteCandidate: "",
      headers: [
        { text: "Email", value: "email" },
        { text: "CV analysis status", value: "cvExtractionStatus" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      expanded: [],
      headersInterviews: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Date and hour", value: "date" },
        { text: "Position", value: "position" },
        { text: "Competencies", value: "competencies" },
        { text: "Interviewer", value: "isIaInterviewer" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      deleteCandidateDialog: false,
      deletingCandidate: false,
      itemToDelete: {},
      itemToCreateInterview: null,
      createInterviewDialog: false,
      rules: [(v) => !!v || "Required field"],
      positions: [],
      analysisTypes: [],
      optionsInterviewer: [
        { label: "Interviewer", value: false },
        { label: "Avatar", value: true },
      ],
      menuDate: false,
      menuTime: false,
      interview: {
        name: "",
        description: "",
        positionId: null,
        analysisTypeId: null,
        isIaInterviewer: false,
        day: null,
        hour: null,
      },
      messageDialog: "",
      successfullDialog: false,
      now: null,
      nowTZ: null,
      nowSpaceSeparator: null,
      creating: false,
      messageCreateInterview: "",
      successfullCreateInterview: false,

      itemToDeleteInterview: null,
      deleteInterviewDialog: false,
      reason: "",
      deletingInterview: false,
      messageDeleteInterview: "",
      successfullDeleteInterview: false,

      itemToEditInterview: {},
      editInterviewDialog: false,
      updating: false,
      menuDateEdit: false,
      menuTimeEdit: false,
      messageDialogEditInterview: "",
      successfullEditInterview: false,
      messageEditInterview: "",

      successfullResendMail: false,
      messageResendMail: "",

      accessInterviewDialog: false,
      accessDate: "",
      itemToAccessInterview: {},
      messageRegisterInterview: "",
      successfullRegisterInterview: false,
    };
  },
  computed: {},
  watch: {
    expanded: {
      handler() {
        this.successfullInterviews = true;
        this.expanded.forEach((item) => {
          let candidateIndex = this.candidates.findIndex(
            (candidate) => candidate.id === item.id
          );
          if (this.candidates[candidateIndex].interviews.length === 0) {
            CandidatesDataService.findAllInterviewsFromCandidate(
              this.candidates[candidateIndex].id
            ).then(
              (response) => {
                if (response.status == 200) {
                  this.candidates[candidateIndex].interviews =
                    response.data.data;

                  this.candidates.forEach((candidate) => {
                    candidate.interviews.forEach((interview) => {
                      interview.candidateId = candidate.id;
                    });
                  });
                  this.$forceUpdate();
                } else {
                  this.successfullInterviews = false;
                  this.messageInterviews =
                    "An error has occurred retrieving the list of interviews. Please, try again later.";
                }
              },
              (error) => {
                this.successfullInterviews = false;
                this.messageInterviews =
                  "An error has occurred retrieving the list of interviews. Please, try again later.";

                if (
                  error.response.status === 401 ||
                  error.response.status === 403
                ) {
                  localStorage.removeItem("user");
                  this.$router.push("/");
                }
                console.error(
                  (error.response && error.response.data) ||
                    error.message ||
                    error.toString()
                );
              }
            );
          }
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.getDateTimeNow();
    await this.getAllCandidates();
    await this.getAllPositions();
    await this.getAllInterviewAnalysisTypes();
  },
  methods: {
    getDateTimeNow() {
      let tmpDate = new Date();

      tmpDate.setHours(tmpDate.getHours() - 1);

      let year = tmpDate.getFullYear();
      let month = String(tmpDate.getMonth() + 1).padStart(2, "0");
      let day = String(tmpDate.getDate()).padStart(2, "0");
      let hours = String(tmpDate.getHours()).padStart(2, "0");
      let minutes = String(tmpDate.getMinutes()).padStart(2, "0");

      this.now = `${year}-${month}-${day}T${hours}:${minutes}`;
      this.nowSpaceSeparator = `${year}-${month}-${day} ${hours}:${minutes}`;
      this.nowTZ = `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
    },
    getAllCandidates() {
      this.loading = true;

      CandidatesDataService.findAll().then(
        (response) => {
          if (response.status == 200) {
            this.candidates = response.data.data;
            this.candidates.forEach((candidate) => {
              candidate.interviews = [];
            });
          } else {
            this.successfullCandidates = false;
            this.messageCandidates =
              "An error has occurred retrieving the list of candidates. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullCandidates = false;
          this.messageCandidates =
            "An error has occurred retrieving the list of candidates. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getAllPositions() {
      this.loading = true;

      PositionsDataService.findAll().then(
        (response) => {
          if (response.status == 200) this.positions = response.data.data;
          else {
            this.successfullPositions = false;
            this.messagePositions =
              "An error has occurred retrieving the list of positions. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullPositions = false;
          this.messagePositions =
            "An error has occurred retrieving the list of positions. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getAllInterviewAnalysisTypes() {
      this.loading = true;

      InterviewsDataService.findAllInterviewAnalysisTypes().then(
        (response) => {
          if (response.status == 200) this.analysisTypes = response.data.data;
          else {
            this.successfullAnalysisTypes = false;
            this.messageAnalysisTypes =
              "An error has occurred retrieving the list of analysis types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullAnalysisTypes = false;
          this.messageAnalysisTypes =
            "An error has occurred retrieving the list of analysis types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    openUploadCVs() {
      this.$router.push("/upload-cv");
    },
    openDeleteCandidate(candidate) {
      this.itemToDelete = { ...candidate };
      this.deleteCandidateDialog = true;
    },
    deleteCandidate() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingCandidate = true;

        CandidatesDataService.delete(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllCandidates();
              this.expanded = [];
              this.messageDeleteCandidate = "";
              this.successfullDeleteCandidate = true;

              setTimeout(() => {
                this.messageDeleteCandidate = "Candidate successfully deleted.";
              }, 10);
              this.$forceUpdate();
            } else {
              this.messageDeleteCandidate = "";
              this.successfullDeleteCandidate = false;

              setTimeout(() => {
                this.messageDeleteCandidate =
                  "An error has occurred deleting the candidate. Please, try again later.";
              }, 10);
            }

            this.deleteCandidateDialog = false;
            this.deletingCandidate = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteCandidate = "";
            this.successfullDeleteCandidate = false;

            setTimeout(() => {
              this.messageDeleteCandidate =
                "An error has occurred deleting the candidate. Please, try again later.";
            }, 10);

            this.deleteCandidateDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingCandidate = false;
          }
        );
      } else {
        this.messageDeleteCandidate = "";
        this.successfullDeleteCandidate = false;

        setTimeout(() => {
          this.messageDeleteCandidate =
            "An error has occurred deleting the candidate. Please, try again later.";
        }, 10);

        this.deleteCandidateDialog = false;
        this.itemToDelete = {};
      }
    },
    getColor(item) {
      if (item === "PENDING") return "accent";
      else if (item === "PROCESSING") return "info";
      else if (item === "COMPLETED") return "success";
      else return "error";
    },
    getColorInterviewStatus(item) {
      if (item === "CREATED") return "info";
      else if (item === "ANALYSING") return "warning";
      else if (item === "ANALYSED") return "success";
      else return "error";
    },
    openCreateInterview(candidate) {
      if (this.$refs.createInterviewForm !== undefined)
        this.$refs.createInterviewForm.reset();
      this.createInterviewDialog = true;
      this.itemToCreateInterview = { ...candidate };
    },
    cancelCreateInterview() {
      this.createInterviewDialog = false;
    },
    createInterview() {
      this.successfullDialog = true;
      this.messageDialog = "";
      if (this.$refs.createInterviewForm.validate()) {
        let dateTime = this.interview.day + "T" + this.interview.hour;
        if (this.checkValidDateTime(dateTime)) {
          this.interview.date = this.getDateTimeTZFormat(
            this.interview.day,
            this.interview.hour
          );

          this.interview.candidateId = this.itemToCreateInterview.id;

          this.creating = true;

          InterviewsDataService.create(this.interview).then(
            (response) => {
              if (response.status == 201) {
                this.getAllCandidates();
                this.expanded = [];
                this.successfullCreateInterview = true;
                this.messageCreateInterview =
                  "Videointerview successfully created.";
                this.createInterviewDialog = false;
                this.$forceUpdate();
              } else {
                this.successfullCreateInterview = false;
                this.messageCreateInterview =
                  "An error has occurred creating the videointerview. Please, try again later.";
                this.createInterviewDialog = false;
              }
              this.creating = false;
            },
            (error) => {
              this.successfullCreateInterview = false;
              this.messageCreateInterview =
                "An error has occurred creating the videointerview. Please, try again later.";
              this.createInterviewDialog = false;
              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              }

              this.creating = false;
            }
          );
        } else {
          this.successfullDialog = false;
          this.messageDialog =
            "The date and time specified must be after the current date and time.";
        }
      }
    },
    openDeleteInterview(interview) {
      this.deleteInterviewDialog = true;
      this.itemToDeleteInterview = { ...interview };
    },
    deleteInterview() {
      if (this.itemToDeleteInterview.id !== undefined) {
        this.deletingInterview = true;
        InterviewsDataService.delete(
          this.itemToDeleteInterview.id,
          this.itemToDeleteInterview.candidateId,
          this.itemToDeleteInterview.position.id,
          this.reason
        ).then(
          (response) => {
            if (response.status == 204) {
              this.getAllCandidates();
              this.expanded = [];
              this.reason = "";
              this.successfullDeleteInterview = true;
              this.messageDeleteInterview =
                "Videointerview successfully deleted.";
              this.$forceUpdate();
            } else {
              this.reason = "";
              this.successfullDeleteInterview = false;
              this.messageDeleteInterview =
                "An error has occurred deleting the videointerview. Please, try again later.";
            }

            this.deleteInterviewDialog = false;
            this.deletingInterview = false;
          },
          (error) => {
            this.reason = "";
            this.successfullDeleteInterview = false;
            this.messageDeleteInterview =
              "An error has occurred deleting the videointerview. Please, try again later.";
            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deleteInterviewDialog = false;
            this.deletingInterview = false;
          }
        );
      } else {
        this.successfullDeleteInterview = false;
        this.messageDeleteInterview =
          "An error has occurred deleting the videointerview. Please, try again later.";
        this.deleteInterviewDialog = false;
      }
    },
    openEditInterview(interview) {
      this.editInterviewDialog = true;
      this.itemToEditInterview = { ...interview };
      this.itemToEditInterview.positionId =
        this.itemToEditInterview.position.id;
      this.itemToEditInterview.analysisTypeId =
        this.itemToEditInterview.interviewAnalysisTypeId;
      let tmpDayHour = this.getDayHourFromTZ(this.itemToEditInterview.date);
      this.itemToEditInterview.day = tmpDayHour[0];
      this.itemToEditInterview.hour = tmpDayHour[1];
    },
    editInterview() {
      this.successfullDialog = true;
      this.messageDialogEditInterview = "";
      if (this.$refs.editInterviewForm.validate()) {
        let dateTime =
          this.itemToEditInterview.day + "T" + this.itemToEditInterview.hour;
        if (this.checkValidDateTime(dateTime)) {
          this.itemToEditInterview.date = this.getDateTimeTZFormat(
            this.itemToEditInterview.day,
            this.itemToEditInterview.hour
          );

          this.updating = true;

          InterviewsDataService.update(this.itemToEditInterview).then(
            (response) => {
              if (response.status == 204) {
                this.getAllCandidates();
                this.expanded = [];
                this.successfullEditInterview = true;
                this.messageEditInterview =
                  "Videointerview successfully edited.";
                this.editInterviewDialog = false;
                this.$forceUpdate();
              } else {
                this.successfullEditInterview = false;
                this.messageEditInterview =
                  "An error has occurred editing the videointerview. Please, try again later.";
                this.editInterviewDialog = false;
              }

              this.updating = false;
            },
            (error) => {
              this.successfullEditInterview = false;
              this.messageEditInterview =
                "An error has occurred editing the videointerview. Please, try again later.";
              this.editInterviewDialog = false;
              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              }

              this.updating = false;
            }
          );
        } else {
          this.successfullDialog = false;
          this.messageDialogEditInterview =
            "The date and time specified must be after the current date and time.";
        }
      }
    },
    cancelEditInterview() {
      this.editInterviewDialog = false;
    },
    resendInterviewMail(interview) {
      InterviewsDataService.resendMail(interview.id).then(
        (response) => {
          if (response.status == 200) {
            this.successfullResendMail = true;
            this.messageResendMail = "Email successfully sent.";
          } else {
            this.successfullResendMail = false;
            this.messageResendMail =
              "An error has occurred sending the email. Please, try again later.";
          }
        },
        (error) => {
          this.successfullResendMail = false;
          this.messageResendMail =
            "An error has occurred sending the email. Please, try again later.";
          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        }
      );
    },

    openAccessInterview(interview) {
      this.itemToAccessInterview = { ...interview };

      let tmpDayHour = this.getDayHourFromTZ(this.itemToAccessInterview.date);
      this.accessDate = tmpDayHour[0] + " " + tmpDayHour[1];

      if (!this.checkStartInterview(this.itemToAccessInterview.date))
        this.accessInterviewDialog = true;
      else this.startInterview();
    },
    checkStartInterview(interviewDate) {
      let nowTZ = new Date(this.nowTZ);
      interviewDate = new Date(interviewDate);

      console.log("nowTZ: ", nowTZ);
      console.log("interviewDate: ", interviewDate);

      interviewDate.setMinutes(interviewDate.getMinutes() - 10);

      if (nowTZ.getTime() >= interviewDate.getTime()) return true;
      else return false;
    },
    startInterview() {
      console.log("itemToAccessInterview: ", this.itemToAccessInterview);

      InterviewsDataService.registerCall(
        this.itemToAccessInterview.candidateId,
        this.itemToAccessInterview.interviewId,
        JSON.parse(localStorage.getItem("user")).id
      ).then(
        (response) => {
          if (response.status == 200) {
            this.$router.push({
              name: "waiting room",
              params: { interview: this.itemToAccessInterview },
            });
            SocketioService.createCallRoom(this.$socket, response.data.data.id);
          } else {
            this.successfullRegisterInterview = false;
            this.messageRegisterInterview =
              "An error occurred while recording the videointerview. Please, try again later.";
          }
        },
        (error) => {
          this.successfullRegisterInterview = false;
          this.messageRegisterInterview =
            "An error occurred while recording the videointerview. Please, try again later.";
          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        }
      );
    },
    openCV(candidate) {
      console.log("candidate: ", candidate);
      this.$router.push({
        name: "cv info",
        params: { cv: candidate },
      });
    },
    showAnalysis(item) {
      console.log("showAnalysis: ", item);
    },
    checkValidDateTime(dateTime) {
      let stringDateTime = new Date(dateTime);
      let stringNow = new Date(this.now);

      if (
        stringNow.getTime() === stringDateTime.getTime() ||
        stringNow.getTime() > stringDateTime.getTime()
      )
        return false;
      else return true;
    },
    getDateTimeTZFormat(date, time) {
      let [year, month, day] = date.split("-").map(Number);
      let [hours, minutes] = time.split(":").map(Number);
      let dateTime = new Date(year, month - 1, day, hours, minutes);

      return dateTime.toISOString();
    },
    getDayHourFromTZ(tzDate) {
      let tzDateSplitted = this.$options.filters.formatDate(tzDate).split(" ");
      return [tzDateSplitted[0], tzDateSplitted[1].substr(0, 5)];
    },
  },
};
</script>
