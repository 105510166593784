<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Environment Backgrounds</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateEnvironmentBackground"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create environment background
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateEnvironmentBackground"
                  outlined
                  :color="
                    successfullCreateEnvironmentBackground ? 'success' : 'error'
                  "
                  :icon="
                    successfullCreateEnvironmentBackground
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateEnvironmentBackground }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteEnvironmentBackground"
                  outlined
                  :color="
                    successfullDeleteEnvironmentBackground ? 'success' : 'error'
                  "
                  :icon="
                    successfullDeleteEnvironmentBackground
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteEnvironmentBackground }}
                </v-alert>
                <v-alert
                  v-if="messageEditEnvironmentBackground"
                  outlined
                  :color="
                    successfullEditEnvironmentBackground ? 'success' : 'error'
                  "
                  :icon="
                    successfullEditEnvironmentBackground
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditEnvironmentBackground }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="environmentBackgrounds"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openEditEnvironmentBackground(item)"
                        >
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit environment background</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openDeleteEnvironmentBackground(item)"
                        >
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete environment background</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageEnvironmentBackgrounds"
              outlined
              :color="successfullEnvironmentBackgrounds ? 'success' : 'error'"
              :icon="
                successfullEnvironmentBackgrounds
                  ? 'mdi-check-circle'
                  : 'mdi-alert'
              "
              dismissible
            >
              {{ messageEnvironmentBackgrounds }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="createEnvironmentBackgroundDialog"
      max-width="800"
      persistent
    >
      <v-card>
        <v-form ref="createEnvironmentBackgroundForm">
          <v-card-title>
            <h5>Create Environment Background</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="environmentBackground.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Description"
                    v-model="environmentBackground.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="py-0 mb-3">
                  <VueFileAgent
                    ref="vueFileAgent"
                    :theme="'list'"
                    :multiple="false"
                    :deletable="true"
                    :meta="true"
                    :disabled="fileReceived"
                    :accept="'image/*'"
                    :maxSize="'10MB'"
                    :editable="false"
                    :helpText="'Select an image for the environment background (uncompressed)'"
                    :errorText="{
                      type: 'Wrong file extension. Please upload only image files (uncompressed).',
                      size: 'The maximum size is 10MB.',
                    }"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                    v-model="fileRecords"
                  ></VueFileAgent>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateEnvironmentBackgroundInside"
                class="mb-0 mt-3"
                outlined
                :color="
                  successfullCreateEnvironmentBackgroundInside
                    ? 'success'
                    : 'error'
                "
                :icon="
                  successfullCreateEnvironmentBackgroundInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateEnvironmentBackgroundInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="cancelCreateEnvironmentBackground"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="createEnvironmentBackground"
              class="ok-modal"
              v-if="!creatingEnvironmentBackground"
            >
              Create
            </v-btn>
            <v-btn
              @click="createEnvironmentBackground"
              class="ok-modal"
              v-else
              disabled
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editEnvironmentBackgroundDialog"
      max-width="800"
      persistent
    >
      <v-card>
        <v-form ref="editEnvironmentBackgroundForm">
          <v-card-title>
            <h5>Update Environment Background</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Description"
                    v-model="itemToEdit.description"
                  ></v-textarea>
                </v-col>
                <v-col cols="2">
                  <h6 class="details-subtitles">Background:</h6>
                </v-col>
                <v-col cols="10">
                  <v-img
                    :aspect-ratio="1"
                    class="bg-white"
                    :src="itemToEdit.image"
                    width="100"
                  />
                </v-col>
                <v-col cols="12" class="py-0 mb-3">
                  <VueFileAgent
                    ref="vueFileAgentUpdate"
                    :theme="'list'"
                    :multiple="false"
                    :deletable="true"
                    :meta="true"
                    :disabled="fileReceivedUpdate"
                    :accept="'image/*'"
                    :maxSize="'10MB'"
                    :editable="false"
                    :helpText="'Select an image to update the previous one for the environment background (uncompressed)'"
                    :errorText="{
                      type: 'Wrong file extension. Please upload only image files (uncompressed).',
                      size: 'The maximum size is 10MB.',
                    }"
                    @select="filesSelectedUpdate($event)"
                    @beforedelete="onBeforeDeleteUpdate($event)"
                    @delete="fileDeletedUpdate($event)"
                    v-model="fileRecordsUpdate"
                  ></VueFileAgent>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageEditEnvironmentBackgroundInside"
                class="mb-0 mt-3"
                outlined
                :color="
                  successfullEditEnvironmentBackgroundInside
                    ? 'success'
                    : 'error'
                "
                :icon="
                  successfullEditEnvironmentBackgroundInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditEnvironmentBackgroundInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="editEnvironmentBackgroundDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="editEnvironmentBackground"
              class="ok-modal"
              v-if="!editingEnvironmentBackground"
            >
              Update
            </v-btn>
            <v-btn
              @click="editEnvironmentBackground"
              class="ok-modal"
              v-else
              disabled
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteEnvironmentBackgroundDialog"
      max-width="550"
      min-height="550"
    >
      <v-card>
        <v-card-title>
          <h5>Delete Environment Background</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected environment background?
            <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteEnvironmentBackgroundDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingEnvironmentBackground">
            <v-btn @click="deleteEnvironmentBackground" class="ok-modal">
              Confirm
            </v-btn>
          </template>
          <v-btn
            @click="deleteEnvironmentBackground"
            class="ok-modal"
            v-else
            disabled
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsEnvironmentBackgroundDialog" max-width="800">
      <v-card>
        <v-form ref="detailsEnvironmentBackgroundForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Description:</h6>
                </v-col>
                <v-col cols="10">
                  <p class="mb-0" v-if="itemToShow.description !== ''">
                    {{ itemToShow.description }}
                  </p>
                  <p class="mb-0" v-else>-</p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2">
                  <h6 class="details-subtitles">Background:</h6>
                </v-col>
                <v-col cols="10">
                  <v-img
                    :aspect-ratio="1"
                    class="bg-white"
                    :src="itemToShow.image"
                    width="100"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsEnvironmentBackgroundDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EnvironmentBackgroundsDataService from "@/services/EnvironmentBackgroundsDataService";
import utils from "@/utils/utils";

export default {
  name: "EnvironmentBackgroundsList",
  data() {
    return {
      environmentBackgrounds: [],
      successfullEnvironmentBackgrounds: false,
      messageEnvironmentBackgrounds: "",
      loading: false,
      successfullCreateEnvironmentBackground: false,
      messageCreateEnvironmentBackground: "",
      successfullCreateEnvironmentBackgroundInside: false,
      messageCreateEnvironmentBackgroundInside: "",
      successfullEditEnvironmentBackground: false,
      messageEditEnvironmentBackground: "",
      successfullEditEnvironmentBackgroundInside: false,
      messageEditEnvironmentBackgroundInside: "",
      successfullDeleteEnvironmentBackground: false,
      messageDeleteEnvironmentBackground: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      environmentBackground: {
        name: "",
        description: "",
        file: null,
      },
      rules: [(v) => !!v || "Required field"],
      createEnvironmentBackgroundDialog: false,
      creatingEnvironmentBackground: false,
      editEnvironmentBackgroundDialog: false,
      editingEnvironmentBackground: false,
      deleteEnvironmentBackgroundDialog: false,
      deletingEnvironmentBackground: false,
      itemToEdit: {},
      itemToDelete: {},
      itemToShow: {},
      showDetailsEnvironmentBackgroundDialog: false,
      fileReceived: false,
      fileRecords: [],
      fileRecordsForUpload: [],
      uploadDisactive: true,
      fileReceivedUpdate: false,
      fileRecordsUpdate: [],
      fileRecordsForUploadUpdate: [],
      uploadDisactiveUpdate: true,
    };
  },
  async mounted() {
    await this.getAllEnvironmentBackgrounds();
  },
  methods: {
    getAllEnvironmentBackgrounds() {
      this.loading = true;

      EnvironmentBackgroundsDataService.findAll().then(
        (response) => {
          if (response.status == 200)
            this.environmentBackgrounds = response.data.data;
          else {
            this.successfullEnvironmentBackgrounds = false;
            this.messageEnvironmentBackgrounds =
              "An error has occurred retrieving the list of environment backgrounds. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullEnvironmentBackgrounds = false;
          this.messageEnvironmentBackgrounds =
            "An error has occurred retrieving the list of environment backgrounds. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    filesSelected: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      if (validFileRecords.length > 0) {
        this.uploadDisactive = false;
        this.fileRecordsForUpload =
          this.fileRecordsForUpload.concat(validFileRecords);
      } else this.uploadDisactive = true;
    },
    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else this.$refs.vueFileAgent.deleteFileRecord(fileRecord);

      this.uploadDisactive =
        this.fileRecordsForUpload.length <= 0 ? true : false;
      // if (this.fileRecordsForUpload.length <= 0) this.message = "";
    },
    fileDeleted: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);

      if (i !== -1) this.fileRecordsForUpload.splice(i, 1);
      else this.deleteUploadedFile(fileRecord);

      this.uploadDisactive =
        this.fileRecordsForUpload.length <= 0 ? true : false;
      // if (this.fileRecordsForUpload.length <= 0) this.message = "";
    },
    deleteUploadedFileUpdate: function (fileRecord) {
      this.$refs.vueFileAgentUpdate.deleteUpload(
        this.uploadUrlUpdate,
        this.uploadHeadersUpdate,
        fileRecord
      );
    },
    filesSelectedUpdate: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      if (validFileRecords.length > 0) {
        this.uploadDisactiveUpdate = false;
        this.fileRecordsForUploadUpdate =
          this.fileRecordsForUploadUpdate.concat(validFileRecords);
      } else this.uploadDisactiveUpdate = true;
    },
    onBeforeDeleteUpdate: function (fileRecord) {
      let i = this.fileRecordsForUploadUpdate.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUploadUpdate.splice(i, 1);
        var k = this.fileRecordsUpdate.indexOf(fileRecord);
        if (k !== -1) this.fileRecordsUpdate.splice(k, 1);
      } else this.$refs.vueFileAgentUpdate.deleteFileRecord(fileRecord);

      this.uploadDisactiveUpdate =
        this.fileRecordsForUploadUpdate.length <= 0 ? true : false;
      // if (this.fileRecordsForUpload.length <= 0) this.message = "";
    },
    fileDeletedUpdate: function (fileRecord) {
      let i = this.fileRecordsForUploadUpdate.indexOf(fileRecord);

      if (i !== -1) this.fileRecordsForUploadUpdate.splice(i, 1);
      else this.deleteUploadedFileUpdate(fileRecord);

      this.uploadDisactiveUpdate =
        this.fileRecordsForUploadUpdate.length <= 0 ? true : false;
      // if (this.fileRecordsForUpload.length <= 0) this.message = "";
    },
    openCreateEnvironmentBackground() {
      this.createEnvironmentBackgroundDialog = true;
    },
    createEnvironmentBackground() {
      this.successfullCreateEnvironmentBackground = true;
      this.messageCreateEnvironmentBackground = "";

      if (this.$refs.createEnvironmentBackgroundForm.validate()) {
        if (this.fileRecordsForUpload.length > 0) {
          this.environmentBackground.file = this.fileRecordsForUpload[0].file;

          let formData = new FormData();
          formData.append(
            "environmentBackground",
            JSON.stringify(this.environmentBackground)
          );

          this.creatingEnvironmentBackground = true;

          EnvironmentBackgroundsDataService.create(
            this.environmentBackground
          ).then(
            (response) => {
              if (response.status == 201) {
                this.getAllEnvironmentBackgrounds();

                this.messageCreateEnvironmentBackground = "";
                this.successfullCreateEnvironmentBackground = true;

                setTimeout(() => {
                  this.messageCreateEnvironmentBackground =
                    "Environment Background successfully created.";
                }, 10);
              } else {
                this.messageCreateEnvironmentBackground = "";
                this.successfullCreateEnvironmentBackground = true;

                setTimeout(() => {
                  this.messageCreateEnvironmentBackground =
                    "An error has occurred creating the environment background. Please, try again later.";
                }, 10);
              }

              this.createEnvironmentBackgroundDialog = false;
              this.creatingEnvironmentBackground = false;
              this.environmentBackground = {
                name: "",
                description: "",
                file: null,
              };

              this.fileReceived = false;
              this.fileRecords = [];
              this.fileRecordsForUpload = [];
              this.uploadDisactive = true;
            },
            (error) => {
              this.messageCreateEnvironmentBackgroundInside = "";
              this.messageCreateEnvironmentBackground = "";

              if (error.response.status === 409) {
                this.successfullCreateEnvironmentBackgroundInside = false;

                setTimeout(() => {
                  this.messageCreateEnvironmentBackgroundInside =
                    "Environment Background already in use. Please, change its name.";
                }, 10);
              } else if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.successfullCreateEnvironmentBackground = false;

                setTimeout(() => {
                  this.messageCreateEnvironmentBackground =
                    "An error has occurred creating the environment background. Please, try again later.";
                }, 10);

                this.createEnvironmentBackgroundDialog = false;
                this.environmentBackground = {
                  name: "",
                  description: "",
                  file: null,
                };

                this.fileReceived = false;
                this.fileRecords = [];
                this.fileRecordsForUpload = [];
                this.uploadDisactive = true;
              }

              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              this.creatingEnvironmentBackground = false;
            }
          );
        } else {
          this.successfullCreateEnvironmentBackgroundInside = false;

          setTimeout(() => {
            this.messageCreateEnvironmentBackgroundInside =
              "Please, upload an image.";
          }, 10);
        }
      }
    },
    cancelCreateEnvironmentBackground() {
      this.createEnvironmentBackgroundDialog = false;
      this.environmentBackground = {
        name: "",
        description: "",
        file: null,
      };
    },
    openEditEnvironmentBackground(environmentBackground) {
      this.itemToEdit = { ...environmentBackground };

      this.itemToEdit.image = utils.config.cloudfrontURL + this.itemToEdit.key;

      this.editEnvironmentBackgroundDialog = true;
    },
    editEnvironmentBackground() {
      this.successfullEditEnvironmentBackground = true;

      if (this.$refs.editEnvironmentBackgroundForm.validate()) {
        if (this.fileRecordsForUploadUpdate.length > 0) {
          this.itemToEdit.file = this.fileRecordsForUploadUpdate[0].file;
        } else {
          this.itemToEdit.file = null;
        }

        let formData = new FormData();
        formData.append(
          "environmentBackground",
          JSON.stringify(this.itemToEdit)
        );

        this.editingEnvironmentBackground = true;

        EnvironmentBackgroundsDataService.update(this.itemToEdit).then(
          (response) => {
            if (response.status == 204) {
              this.getAllEnvironmentBackgrounds();
              this.messageEditEnvironmentBackground = "";
              this.successfullEditEnvironmentBackground = true;

              setTimeout(() => {
                this.messageEditEnvironmentBackground =
                  "Environment Background successfully updated.";
              }, 10);
            } else {
              this.messageEditEnvironmentBackground = "";
              this.successfullEditEnvironmentBackground = true;

              setTimeout(() => {
                this.messageEditEnvironmentBackground =
                  "An error has occurred updating the environment background. Please, try again later.";
              }, 10);
            }

            this.editEnvironmentBackgroundDialog = false;
            this.editingEnvironmentBackground = false;
            this.itemToEdit = {};

            this.fileReceivedUpdate = false;
            this.fileRecordsUpdate = [];
            this.fileRecordsForUploadUpdate = [];
            this.uploadDisactiveUpdate = true;
          },
          (error) => {
            this.messageEditEnvironmentBackgroundInside = "";
            this.messageEditEnvironmentBackground = "";

            if (error.response.status === 409) {
              this.successfullEditEnvironmentBackgroundInside = false;

              setTimeout(() => {
                this.messageEditEnvironmentBackgroundInside =
                  "Environment Background already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditEnvironmentBackground = false;

              setTimeout(() => {
                this.messageEditEnvironmentBackground =
                  "An error has occurred updating the environment background. Please, try again later.";
              }, 10);

              this.editEnvironmentBackgroundDialog = false;
              this.itemToEdit = {};
            }

            this.fileReceivedUpdate = false;
            this.fileRecordsUpdate = [];
            this.fileRecordsForUploadUpdate = [];
            this.uploadDisactiveUpdate = true;

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingEnvironmentBackground = false;
          }
        );
      }
    },
    openDeleteEnvironmentBackground(environmentBackground) {
      this.itemToDelete = { ...environmentBackground };
      this.deleteEnvironmentBackgroundDialog = true;
    },
    deleteEnvironmentBackground() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingEnvironmentBackground = true;

        EnvironmentBackgroundsDataService.delete(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllEnvironmentBackgrounds();
              this.messageDeleteEnvironmentBackground = "";
              this.successfullDeleteEnvironmentBackground = true;

              setTimeout(() => {
                this.messageDeleteEnvironmentBackground =
                  "Environment Background successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteEnvironmentBackground = "";
              this.successfullDeleteEnvironmentBackground = false;

              setTimeout(() => {
                this.messageDeleteEnvironmentBackground =
                  "An error has occurred deleting the environment background. Please, try again later.";
              }, 10);
            }

            this.deleteEnvironmentBackgroundDialog = false;
            this.deletingEnvironmentBackground = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteEnvironmentBackground = "";
            this.successfullDeleteEnvironmentBackground = false;

            setTimeout(() => {
              this.messageDeleteEnvironmentBackground =
                "An error has occurred deleting the environment background. Please, try again later.";
            }, 10);

            this.deleteEnvironmentBackgroundDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingEnvironmentBackground = false;
          }
        );
      } else {
        this.messageDeleteEnvironmentBackground = "";
        this.successfullDeleteEnvironmentBackground = false;

        setTimeout(() => {
          this.messageDeleteEnvironmentBackground =
            "An error has occurred deleting the environment background. Please, try again later.";
        }, 10);

        this.deleteEnvironmentBackgroundDialog = false;
        this.itemToDelete = {};
      }
    },
    openShowDetails(environmentBackground) {
      this.itemToShow = { ...environmentBackground };
      this.itemToShow.image = utils.config.cloudfrontURL + this.itemToShow.key;
      this.showDetailsEnvironmentBackgroundDialog = true;
    },
  },
};
</script>
