<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Virtual Profiler Animations</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreateAnimation"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create animation
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreateAnimation"
                  outlined
                  :color="successfullCreateAnimation ? 'success' : 'error'"
                  :icon="
                    successfullCreateAnimation
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreateAnimation }}
                </v-alert>
                <v-alert
                  v-if="messageDeleteAnimation"
                  outlined
                  :color="successfullDeleteAnimation ? 'success' : 'error'"
                  :icon="
                    successfullDeleteAnimation
                      ? 'mdi-check-circle'
                      : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeleteAnimation }}
                </v-alert>
                <v-alert
                  v-if="messageEditAnimation"
                  outlined
                  :color="successfullEditAnimation ? 'success' : 'error'"
                  :icon="
                    successfullEditAnimation ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditAnimation }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="animations"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openEditAnimation(item)">
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit animation</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          @click="openDeleteAnimation(item)"
                        >
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete animation</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messageAnimations"
              outlined
              :color="successfullAnimations ? 'success' : 'error'"
              :icon="successfullAnimations ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageAnimations }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createAnimationDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createAnimationForm">
          <v-card-title>
            <h5>Create Animation</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="animation.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <label>File *</label>
                  <VueFileAgent
                    ref="vueFileAgentCreate"
                    :theme="'list'"
                    :multiple="false"
                    :deletable="true"
                    :meta="true"
                    :disabled="fileReceivedCreate"
                    :accept="'.zip'"
                    :maxSize="'10MB'"
                    :editable="false"
                    :helpText="'Select .zip files to upload the animation files'"
                    :errorText="{
                      type: 'Wrong file extension. Please upload only .zip files.',
                    }"
                    @select="filesSelectedCreate($event)"
                    @beforedelete="onBeforeDeleteCreate($event)"
                    @delete="fileDeletedCreate($event)"
                    v-model="fileRecordsCreate"
                  ></VueFileAgent>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreateAnimationInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullCreateAnimationInside ? 'success' : 'error'"
                :icon="
                  successfullCreateAnimationInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreateAnimationInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreateAnimation" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn
              @click="createAnimation"
              class="ok-modal"
              v-if="!creatingAnimation"
            >
              Create
            </v-btn>
            <v-btn @click="createAnimation" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editAnimationDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editAnimationForm">
          <v-card-title>
            <h5>Update Animation</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="py-0">
                  <label>File *</label>
                  <VueFileAgent
                    ref="vueFileAgentEdit"
                    :theme="'list'"
                    :multiple="false"
                    :deletable="true"
                    :meta="true"
                    :disabled="fileReceivedEdit"
                    :accept="'.zip'"
                    :maxSize="'10MB'"
                    :editable="false"
                    :helpText="'Select .zip files to upload the animation files'"
                    :errorText="{
                      type: 'Wrong file extension. Please upload only .zip files.',
                    }"
                    @select="filesSelectedEdit($event)"
                    @beforedelete="onBeforeDeleteEdit($event)"
                    @delete="fileDeletedEdit($event)"
                    v-model="fileRecordsEdit"
                  ></VueFileAgent>
                </v-col>
              </v-row>

              <v-alert
                v-if="messageEditAnimationInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullEditAnimationInside ? 'success' : 'error'"
                :icon="
                  successfullEditAnimationInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditAnimationInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="editAnimationDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="editAnimation"
              class="ok-modal"
              v-if="!editingAnimation"
            >
              Update
            </v-btn>
            <v-btn @click="editAnimation" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteAnimationDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete Animation</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected animation? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteAnimationDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingAnimation">
            <v-btn @click="deleteAnimation" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="deleteAnimation" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VirtualProfilersDataService from "@/services/VirtualProfilersDataService";

export default {
  name: "VirtualProfilerAnimationsList",
  data() {
    return {
      animations: [],
      successfullAnimations: false,
      messageAnimations: "",
      loading: false,
      successfullCreateAnimation: false,
      messageCreateAnimation: "",
      successfullCreateAnimationInside: false,
      messageCreateAnimationInside: "",
      successfullEditAnimation: false,
      messageEditAnimation: "",
      successfullEditAnimationInside: false,
      messageEditAnimationInside: "",
      successfullDeleteAnimation: false,
      messageDeleteAnimation: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      animation: {
        label: "",
        file: null,
      },
      rules: [(v) => !!v || "Required field"],
      createAnimationDialog: false,
      creatingAnimation: false,
      fileReceivedCreate: false,
      fileRecordsCreate: [],
      fileRecordsForUploadCreate: [],
      uploadDisactiveCreate: true,
      editAnimationDialog: false,
      editingAnimation: false,
      fileReceivedEdit: false,
      fileRecordsEdit: [],
      fileRecordsForUploadEdit: [],
      uploadDisactiveEdit: true,
      deleteAnimationDialog: false,
      deletingAnimation: false,
      itemToEdit: {},
      itemToDelete: {},
    };
  },
  async mounted() {
    await this.getAllAnimations();
  },
  methods: {
    getAllAnimations() {
      this.loading = true;

      VirtualProfilersDataService.findAllVirtualProfilerAnimations().then(
        (response) => {
          if (response.status == 200) this.animations = response.data.data;
          else {
            this.successfullAnimations = false;
            this.messageAnimations =
              "An error has occurred retrieving the list of animations. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullAnimations = false;
          this.messageAnimations =
            "An error has occurred retrieving the list of animations. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    deleteUploadedFileCreate: function (fileRecord) {
      this.$refs.vueFileAgentCreate.deleteUpload(
        this.uploadUrlCreate,
        this.uploadHeadersCreate,
        fileRecord
      );
    },
    filesSelectedCreate: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      if (validFileRecords.length > 0) {
        this.uploadDisactiveCreate = false;
        this.fileRecordsForUploadCreate =
          this.fileRecordsForUploadCreate.concat(validFileRecords);
      } else this.uploadDisactiveCreate = true;
    },
    onBeforeDeleteCreate: function (fileRecord) {
      let i = this.fileRecordsForUploadCreate.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUploadCreate.splice(i, 1);
        var k = this.fileRecordsCreate.indexOf(fileRecord);
        if (k !== -1) this.fileRecordsCreate.splice(k, 1);
      } else this.$refs.vueFileAgentCreate.deleteFileRecord(fileRecord);

      this.uploadDisactiveCreate =
        this.fileRecordsForUploadCreate.length <= 0 ? true : false;
      if (this.fileRecordsForUploadCreate.length <= 0) this.message = "";
    },
    fileDeletedCreate: function (fileRecord) {
      let i = this.fileRecordsForUploadCreate.indexOf(fileRecord);

      if (i !== -1) this.fileRecordsForUploadCreate.splice(i, 1);
      else this.deleteUploadedFileCreate(fileRecord);

      this.uploadDisactiveCreate =
        this.fileRecordsForUploadCreate.length <= 0 ? true : false;
      if (this.fileRecordsForUploadCreate.length <= 0) this.message = "";
    },
    openCreateAnimation() {
      this.animation = {
        name: "",
        file: null,
      };
      this.fileRecordsCreate = [];
      this.createAnimationDialog = true;
    },
    createAnimation() {
      this.successfullCreateAnimation = true;
      this.messageCreateAnimation = "";

      if (this.$refs.createAnimationForm.validate()) {
        this.creatingAnimation = true;

        if (this.fileRecordsForUploadCreate.length > 0) {
          this.animation.file = this.fileRecordsForUploadCreate[0].file;
          let formData = new FormData();
          formData.append("name", this.animation.name);
          formData.append(
            "file",
            this.animation.file,
            this.animation.file.name
          );

          VirtualProfilersDataService.createVirtualProfilerAnimation(
            formData
          ).then(
            (response) => {
              if (response.status == 201) {
                this.getAllAnimations();

                this.messageCreateAnimation = "";
                this.successfullCreateAnimation = true;

                setTimeout(() => {
                  this.messageCreateAnimation =
                    "Animation successfully created.";
                }, 10);
              } else {
                this.messageCreateAnimation = "";
                this.successfullCreateAnimation = true;

                setTimeout(() => {
                  this.messageCreateAnimation =
                    "An error has occurred creating the animation. Please, try again later.";
                }, 10);
              }

              this.createAnimationDialog = false;
              this.creatingAnimation = false;
              this.animation = {
                name: "",
                file: null,
              };
              this.fileRecordsCreate = [];
            },
            (error) => {
              this.messageCreateAnimationInside = "";
              this.messageCreateAnimation = "";

              if (error.response.status === 409) {
                this.successfullCreateAnimationInside = false;

                setTimeout(() => {
                  this.messageCreateAnimationInside =
                    "Animation already in use. Please, change its name.";
                }, 10);
              } else if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.successfullCreateAnimation = false;

                setTimeout(() => {
                  this.messageCreateAnimation =
                    "An error has occurred creating the animation. Please, try again later.";
                }, 10);

                this.createAnimationDialog = false;
                this.animation = {
                  name: "",
                  file: null,
                };
                this.fileRecordsCreate = [];
              }

              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              this.creatingAnimation = false;
            }
          );
        } else {
          this.successfullCreateAnimationInside = false;

          setTimeout(() => {
            this.messageCreateAnimationInside =
              "Please, select a file for the animation.";
          }, 10);

          this.creatingAnimation = false;
        }
      }
    },
    cancelCreateAnimation() {
      this.createAnimationDialog = false;
      this.animation = {
        name: "",
        file: null,
      };
      this.fileRecordsCreate = [];
    },
    deleteUploadedFileEdit: function (fileRecord) {
      this.$refs.vueFileAgentEdit.deleteUpload(
        this.uploadUrlEdit,
        this.uploadHeadersEdit,
        fileRecord
      );
    },
    filesSelectedEdit: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      if (validFileRecords.length > 0) {
        this.uploadDisactiveEdit = false;
        this.fileRecordsForUploadEdit =
          this.fileRecordsForUploadEdit.concat(validFileRecords);
      } else this.uploadDisactiveEdit = true;
    },
    onBeforeDeleteEdit: function (fileRecord) {
      let i = this.fileRecordsForUploadEdit.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUploadEdit.splice(i, 1);
        var k = this.fileRecordsEdit.indexOf(fileRecord);
        if (k !== -1) this.fileRecordsEdit.splice(k, 1);
      } else this.$refs.vueFileAgentEdit.deleteFileRecord(fileRecord);

      this.uploadDisactiveEdit =
        this.fileRecordsForUploadEdit.length <= 0 ? true : false;
      if (this.fileRecordsForUploadEdit.length <= 0) this.message = "";
    },
    fileDeletedEdit: function (fileRecord) {
      let i = this.fileRecordsForUploadEdit.indexOf(fileRecord);

      if (i !== -1) this.fileRecordsForUploadEdit.splice(i, 1);
      else this.deleteUploadedFileEdit(fileRecord);

      this.uploadDisactiveEdit =
        this.fileRecordsForUploadEdit.length <= 0 ? true : false;
      if (this.fileRecordsForUploadEdit.length <= 0) this.message = "";
    },
    openEditAnimation(animation) {
      this.itemToEdit = { ...animation };
      this.editAnimationDialog = true;
    },
    editAnimation() {
      this.successfullEditAnimation = true;

      if (this.$refs.editAnimationForm.validate()) {
        this.editingAnimation = true;

        let formData = new FormData();
        formData.append("name", this.itemToEdit.name);
        if (this.fileRecordsForUploadEdit.length > 0) {
          this.itemToEdit.file = this.fileRecordsForUploadEdit[0].file;
          formData.append(
            "file",
            this.itemToEdit.file,
            this.itemToEdit.file.name
          );
        }

        VirtualProfilersDataService.updateVirtualProfilerAnimation(
          formData,
          this.itemToEdit.id
        ).then(
          (response) => {
            if (response.status == 204) {
              this.getAllAnimations();
              this.messageEditAnimation = "";
              this.successfullEditAnimation = true;

              setTimeout(() => {
                this.messageEditAnimation = "Animation successfully updated.";
              }, 10);
            } else {
              this.messageEditAnimation = "";
              this.successfullEditAnimation = true;

              setTimeout(() => {
                this.messageEditAnimation =
                  "An error has occurred updating the animation. Please, try again later.";
              }, 10);
            }

            this.editAnimationDialog = false;
            this.editingAnimation = false;
            this.itemToEdit = {};
          },
          (error) => {
            this.messageEditAnimationInside = "";
            this.messageEditAnimation = "";

            if (error.response.status === 409) {
              this.successfullEditAnimationInside = false;

              setTimeout(() => {
                this.messageEditAnimationInside =
                  "Animation already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditAnimation = false;

              setTimeout(() => {
                this.messageEditAnimation =
                  "An error has occurred updating the animation. Please, try again later.";
              }, 10);

              this.editAnimationDialog = false;
              this.itemToEdit = {};
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingAnimation = false;
          }
        );
      }
    },
    openDeleteAnimation(animation) {
      this.itemToDelete = { ...animation };
      this.deleteAnimationDialog = true;
    },
    deleteAnimation() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingAnimation = true;

        VirtualProfilersDataService.deleteVirtualProfilerAnimation(
          this.itemToDelete.id
        ).then(
          (response) => {
            if (response.status == 204) {
              this.getAllAnimations();
              this.messageDeleteAnimation = "";
              this.successfullDeleteAnimation = true;

              setTimeout(() => {
                this.messageDeleteAnimation = "Animation successfully deleted.";
              }, 10);
            } else {
              this.messageDeleteAnimation = "";
              this.successfullDeleteAnimation = false;

              setTimeout(() => {
                this.messageDeleteAnimation =
                  "An error has occurred deleting the animation. Please, try again later.";
              }, 10);
            }

            this.deleteAnimationDialog = false;
            this.deletingAnimation = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeleteAnimation = "";
            this.successfullDeleteAnimation = false;

            setTimeout(() => {
              this.messageDeleteAnimation =
                "An error has occurred deleting the animation. Please, try again later.";
            }, 10);

            this.deleteAnimationDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingAnimation = false;
          }
        );
      } else {
        this.messageDeleteAnimation = "";
        this.successfullDeleteAnimation = false;

        setTimeout(() => {
          this.messageDeleteAnimation =
            "An error has occurred deleting the animation. Please, try again later.";
        }, 10);

        this.deleteAnimationDialog = false;
        this.itemToDelete = {};
      }
    },
  },
};
</script>
