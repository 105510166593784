import http from "../http-common";

const API_URL_PATH = "users/";

class UsersDataService {
  findAll() {
    return http.get(API_URL_PATH);
  }
}

export default new UsersDataService();
