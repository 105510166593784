import http from "../http-common";

const API_URL_PATH = "interviews/";
const API_URL_PATH_TYPES = "interviews-type/";
const API_URL_PATH_ANALYSIS_TYPES = "interviews-analysis-type/";
const API_URL_PATH_INFO = "utils/feedbacks/";

class InterviewsDataService {
  findAll() {
    return http.get(API_URL_PATH);
  }

  findOne(id) {
    return http.get(API_URL_PATH + id);
  }

  create(interview) {
    return http.post(API_URL_PATH, interview);
  }

  update(interview) {
    return http.put(API_URL_PATH + interview.id, interview);
  }

  delete(id, idCandidate, idPosition) {
    return http.delete(API_URL_PATH + id, {
      data: {
        candidateId: idCandidate,
        positionId: idPosition,
      },
    });
  }

  resendMail(interview_id) {
    return http.get(API_URL_PATH + "resend-email?interviewId=" + interview_id);
  }

  registerCall(idCandidate, idInterview, idInterviewer) {
    return http.get(
      API_URL_PATH +
        "call?candidateId=" +
        idCandidate +
        "&interviewId=" +
        idInterview +
        "&interviewerId=" +
        idInterviewer
    );
  }

  getInterviewInfo(interviewId) {
    return http.get(API_URL_PATH + interviewId);
  }

  findAllInterviewTypes() {
    return http.get(API_URL_PATH_TYPES);
  }

  findOneInterviewType(id) {
    return http.get(API_URL_PATH_TYPES + id);
  }

  createInterviewType(type) {
    return http.post(API_URL_PATH_TYPES, type);
  }

  updateInterviewType(type) {
    return http.put(API_URL_PATH_TYPES + type.id, type);
  }

  deleteInterviewType(id) {
    return http.delete(API_URL_PATH_TYPES + id);
  }

  findAllInterviewAnalysisTypes() {
    return http.get(API_URL_PATH_ANALYSIS_TYPES);
  }

  findOneInterviewAnalysisType(id) {
    return http.get(API_URL_PATH_ANALYSIS_TYPES + id);
  }

  createInterviewAnalysisType(type) {
    return http.post(API_URL_PATH_ANALYSIS_TYPES, type);
  }

  updateInterviewAnalysisType(type) {
    return http.put(API_URL_PATH_ANALYSIS_TYPES + type.id, type);
  }

  deleteInterviewAnalysisType(id) {
    return http.delete(API_URL_PATH_ANALYSIS_TYPES + id);
  }

  getFeedbackInfo() {
    return http.get(API_URL_PATH_INFO);
  }

  sendFeedback(opinion, comment, candidateId, interviewId) {
    let data = {
      feedback: opinion.toUpperCase(),
      comment: comment,
      candidateId: candidateId,
    };
    return http.post(API_URL_PATH + interviewId + "/feedback/", data);
  }
}

export default new InterviewsDataService();
