module.exports = {
	api: {
		protocol: process.env.VUE_APP_API_PROTOCOL || "http",
		host: process.env.VUE_APP_API_HOST || "localhost",
		port: process.env.VUE_APP_API_PORT || "3004",
	},
	config: {
		defaultEmail: process.env.VUE_APP_DEFAULT_EMAIL || "info@virtual.com",
		cloudfrontURL:
			process.env.VUE_APP_CLOUDFRONT_URL ||
			"https://d1q6f0aelx0por.cloudfront.net",
	},
};
