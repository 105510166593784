import axios from "axios";
import utils from "./utils/utils";

export default axios.create({
	baseURL: `${utils.api.protocol}://${utils.api.host}:${utils.api.port}/api/v1/`,
	withCredentials: true,
	headers: {
		"Content-type": "application/json",
	},
});
