<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col cols="12">
            <v-row no-gutters class="pt-8 align-center justify-center">
              <v-col
                cols="12"
                v-for="(card, index) in cards"
                :key="index"
                sm="5"
                md="4"
              >
                <v-card @click="openFunctionality(card.id)" class="mx-8 mb-8">
                  <v-card-title>{{ card.title }}</v-card-title>
                  <v-card-text>{{ card.content }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      cards: [
        {
          id: 0,
          title: "Jobs Management",
          content: "Management of existing jobs.",
        },
        {
          id: 1,
          title: "Contexts Management",
          content: "Management of existing contexts.",
        },
        {
          id: 2,
          title: "Competencies Management",
          content: "Management of existing competencies.",
        },
        {
          id: 3,
          title: "Positions Management",
          content: "Management of existing positions.",
        },
        {
          id: 4,
          title: "Interview Types Management",
          content: "Management of existing interview types.",
        },
        {
          id: 5,
          title: "Component Types Management",
          content: "Management of existing component types.",
        },
        {
          id: 6,
          title: "Components Management",
          content: "Management of existing components.",
        },
        {
          id: 7,
          title: "Interview Analysis Types Management",
          content: "Management of existing interview analysis types.",
        },
        {
          id: 8,
          title: "Environment Backgrounds Management",
          content: "Management of existing environment backgrounds.",
        },
        {
          id: 9,
          title: "Virtual Profiler Management",
          content: "Management of existing virtual profilers.",
        },
        {
          id: 10,
          title: "Virtual Profiler Types Management",
          content: "Management of existing virtual profiler types.",
        },
        {
          id: 11,
          title: "Virtual Profiler Animations Management",
          content: "Management of existing virtual profiler animations.",
        },
        {
          id: 12,
          title: "Candidates Management",
          content: "Management of existing candidates.",
        },
        {
          id: 13,
          title: "Users Management",
          content: "Management of existing users.",
        },
      ],
    };
  },
  components: {},
  created() {},
  mounted() {},
  beforeUnmount() {},
  methods: {
    openFunctionality(id) {
      if (id == 0) {
        this.openJobsManagement();
      } else if (id == 1) {
        this.openContextsManagement();
      } else if (id == 2) {
        this.openCompetenciesManagement();
      } else if (id == 3) {
        this.openPositionsManagement();
      } else if (id == 4) {
        this.openInterviewTypesManagement();
      } else if (id == 5) {
        this.openComponentTypesManagement();
      } else if (id == 6) {
        this.openComponentsManagement();
      } else if (id == 7) {
        this.openInterviewAnalysisTypesManagement();
      } else if (id == 8) {
        this.openEnvironmentBackgroundsManagement();
      } else if (id == 9) {
        this.openVirtualProfilersManagement();
      } else if (id == 10) {
        this.openVirtualProfilerTypesManagement();
      } else if (id == 11) {
        this.openVirtualProfilerAnimationsManagement();
      } else if (id == 12) {
        this.openCandidatesManagement();
      } else if (id == 13) {
        this.openUsersManagement();
      }
    },
    openJobsManagement() {
      this.$router.push("/jobs");
    },
    openContextsManagement() {
      this.$router.push("/contexts");
    },
    openCompetenciesManagement() {
      this.$router.push("/competencies");
    },
    openPositionsManagement() {
      this.$router.push("/positions");
    },
    openInterviewTypesManagement() {
      this.$router.push("/interview-types");
    },
    openComponentTypesManagement() {
      this.$router.push("/component-types");
    },
    openComponentsManagement() {
      this.$router.push("/components");
    },
    openInterviewAnalysisTypesManagement() {
      this.$router.push("/interview-analysis-types");
    },
    openEnvironmentBackgroundsManagement() {
      this.$router.push("/environment-backgrounds");
    },
    openVirtualProfilersManagement() {
      this.$router.push("/virtual-profilers");
    },
    openVirtualProfilerTypesManagement() {
      this.$router.push("/virtual-profiler-types");
    },
    openVirtualProfilerAnimationsManagement() {
      this.$router.push("/virtual-profiler-animations");
    },
    openCandidatesManagement() {
      this.$router.push("/candidates");
    },
    openUsersManagement() {
      this.$router.push("/users");
    },
  },
};
</script>
