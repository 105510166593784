<template>
  <v-container fluid fill-height>
    <v-layout>
      <v-flex>
        <v-row class="fill-height" no-gutters>
          <v-col class="col col-12 col-sm-12 col-md-12">
            <v-row>
              <v-col class="col col-12">
                <v-card-title>
                  <v-row>
                    <v-col class="col col-3">
                      <h1>Positions</h1>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="col col-2">
                      <v-btn
                        @click="openCreatePosition"
                        class="new-item-btn float-right"
                      >
                        <v-icon size="20">mdi-plus</v-icon>
                        &nbsp;&nbsp;Create position
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-alert
                  v-if="messageCreatePosition"
                  outlined
                  :color="successfullCreatePosition ? 'success' : 'error'"
                  :icon="
                    successfullCreatePosition ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageCreatePosition }}
                </v-alert>
                <v-alert
                  v-if="messageDeletePosition"
                  outlined
                  :color="successfullDeletePosition ? 'success' : 'error'"
                  :icon="
                    successfullDeletePosition ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageDeletePosition }}
                </v-alert>
                <v-alert
                  v-if="messageEditPosition"
                  outlined
                  :color="successfullEditPosition ? 'success' : 'error'"
                  :icon="
                    successfullEditPosition ? 'mdi-check-circle' : 'mdi-alert'
                  "
                  dismissible
                >
                  {{ messageEditPosition }}
                </v-alert>
                <v-data-table
                  :headers="headers"
                  :items="positions"
                  item-key="id"
                  :loading="loading"
                  loading-text="Loading... Please, wait..."
                  multi-sort
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openShowDetails(item)">
                          <v-icon size="20" color="primary">mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Show details</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openEditPosition(item)">
                          <v-icon size="20" color="primary"
                            >mdi-square-edit-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit position</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="openDeletePosition(item)">
                          <v-icon size="20" color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete position</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-alert
              class="mt-4"
              v-if="messagePositions"
              outlined
              :color="successfullPositions ? 'success' : 'error'"
              :icon="successfullPositions ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messagePositions }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageJobs"
              outlined
              :color="successfullJobs ? 'success' : 'error'"
              :icon="successfullJobs ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageJobs }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageInterviewTypes"
              outlined
              :color="successfullInterviewTypes ? 'success' : 'error'"
              :icon="
                successfullInterviewTypes ? 'mdi-check-circle' : 'mdi-alert'
              "
              dismissible
            >
              {{ messageInterviewTypes }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageCompetencies"
              outlined
              :color="successfullCompetencies ? 'success' : 'error'"
              :icon="successfullCompetencies ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageCompetencies }}
            </v-alert>

            <v-alert
              class="mt-4"
              v-if="messageContexts"
              outlined
              :color="successfullContexts ? 'success' : 'error'"
              :icon="successfullContexts ? 'mdi-check-circle' : 'mdi-alert'"
              dismissible
            >
              {{ messageContexts }}
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="createPositionDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="createPositionForm">
          <v-card-title>
            <h5>Create Position</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="position.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Description *"
                    v-model="position.description"
                    :rules="rules"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Public name *"
                    v-model="position.publicName"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Public description *"
                    v-model="position.publicDescription"
                    :rules="rules"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Job *"
                    :items="jobs"
                    v-model="position.jobId"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Interview type *"
                    :items="interviewTypes"
                    v-model="position.interviewTypeId"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Competencies *"
                    :items="competencies"
                    v-model="position.competenciesIds"
                    item-text="name"
                    item-value="id"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Contexts *"
                    :items="contexts"
                    v-model="position.contexts"
                    item-text="name"
                    item-value="id"
                    multiple
                  ></v-select>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageCreatePositionInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullCreatePositionInside ? 'success' : 'error'"
                :icon="
                  successfullCreatePositionInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageCreatePositionInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn text @click="cancelCreatePosition" class="cancel-modal">
              Cancel
            </v-btn>
            <v-btn
              @click="createPosition"
              class="ok-modal"
              v-if="!creatingPosition"
            >
              Create
            </v-btn>
            <v-btn @click="createPosition" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editPositionDialog" max-width="800" persistent>
      <v-card>
        <v-form ref="editPositionForm">
          <v-card-title>
            <h5>Edit Position</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Name *"
                    v-model="itemToEdit.name"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Description *"
                    v-model="itemToEdit.description"
                    :rules="rules"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    label="Public name *"
                    v-model="itemToEdit.publicName"
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    label="Public description *"
                    v-model="itemToEdit.publicDescription"
                    :rules="rules"
                  ></v-textarea>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Job *"
                    :items="jobs"
                    v-model="itemToEdit.jobId"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Interview type *"
                    :items="interviewTypes"
                    v-model="itemToEdit.interviewTypeId"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Competencies *"
                    :items="competencies"
                    v-model="itemToEdit.competenciesIds"
                    item-text="name"
                    item-value="id"
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-select
                    label="Contexts *"
                    :items="contexts"
                    v-model="itemToEdit.contexts"
                    item-text="name"
                    item-value="id"
                    multiple
                  ></v-select>
                </v-col>
              </v-row>
              <v-alert
                v-if="messageEditPositionInside"
                class="mb-0 mt-3"
                outlined
                :color="successfullEditPositionInside ? 'success' : 'error'"
                :icon="
                  successfullEditPositionInside
                    ? 'mdi-check-circle'
                    : 'mdi-alert'
                "
                dismissible
              >
                {{ messageEditPositionInside }}
              </v-alert>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="editPositionDialog = false"
              class="cancel-modal"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="editPosition"
              class="ok-modal"
              v-if="!editingPosition"
            >
              Edit
            </v-btn>
            <v-btn @click="editPosition" class="ok-modal" v-else disabled>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deletePositionDialog" max-width="550" min-height="550">
      <v-card>
        <v-card-title>
          <h5>Delete Position</h5>
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="mb-0">
            Do you really want to delete the selected position? <br />
            If so, press Confirm.
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deletePositionDialog = false"
            class="cancel-modal"
          >
            Cancel
          </v-btn>

          <template v-if="!deletingPosition">
            <v-btn @click="deletePosition" class="ok-modal"> Confirm </v-btn>
          </template>
          <v-btn @click="deletePosition" class="ok-modal" v-else disabled>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailsPositionDialog" max-width="800">
      <v-card>
        <v-form ref="detailsPositionForm">
          <v-card-title>
            <h5>Details</h5>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Name:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">{{ itemToShow.name }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Description:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">{{ itemToShow.description }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Public name:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">{{ itemToShow.publicName }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Public description:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">{{ itemToShow.publicDescription }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Job:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0" v-if="itemToShow.job !== undefined">
                    {{ itemToShow.job.name }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <h6 class="details-subtitles">Interview type:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0" v-if="itemToShow.interviewType !== undefined">
                    {{ itemToShow.interviewType.name }}
                  </p>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  itemToShow.contexts !== undefined &&
                  itemToShow.contexts.length == 0
                "
              >
                <v-col cols="3">
                  <h6 class="details-subtitles">Contexts:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">Position with no associated contexts</p>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="12" class="pb-0">
                  <h6 class="details-subtitles">Contexts:</h6>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-data-table
                    :headers="headersDetails"
                    :items="itemToShow.contexts"
                    item-key="itemToShow.id"
                  ></v-data-table>
                </v-col>
              </v-row>

              <v-row
                v-if="
                  itemToShow.competencies !== undefined &&
                  itemToShow.competencies.length == 0
                "
              >
                <v-col cols="3">
                  <h6 class="details-subtitles">Competencies:</h6>
                </v-col>
                <v-col cols="9">
                  <p class="mb-0">Position with no associated competencies</p>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="12" class="pb-0">
                  <h6 class="details-subtitles">Competencies:</h6>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-data-table
                    :headers="headersDetailsCompetencies"
                    :items="itemToShow.competencies"
                    item-key="itemToShow.id"
                  ></v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="showDetailsPositionDialog = false"
              class="cancel-modal"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PositionsDataService from "@/services/PositionsDataService";
import InterviewsDataService from "@/services/InterviewsDataService";
import JobsDataService from "@/services/JobsDataService";
import CompetenciesDataService from "@/services/CompetenciesDataService";
import ContextsDataService from "@/services/ContextsDataService";

export default {
  name: "PositionsList",
  data() {
    return {
      positions: [],
      successfullPositions: false,
      messagePositions: "",
      jobs: [],
      successfullJobs: false,
      messageJobs: "",
      interviewTypes: [],
      successfullInterviewTypes: false,
      messageInterviewTypes: "",
      competencies: [],
      successfullCompetencies: false,
      messageCompetencies: "",
      contexts: [],
      successfullContexts: false,
      messageContexts: "",
      users: [],
      successfullUsers: false,
      messageUsers: "",
      loading: false,
      successfullCreatePosition: false,
      messageCreatePosition: "",
      successfullCreatePositionInside: false,
      messageCreatePositionInside: "",
      successfullEditPosition: false,
      messageEditPosition: "",
      successfullEditPositionInside: false,
      messageEditPositionInside: "",
      successfullDeletePosition: false,
      messageDeletePosition: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Public Name", value: "publicName" },
        { text: "Job", value: "job.name" },
        { text: "Interview type", value: "interviewType.name" },
        { text: "Number of competencies", value: "competencies.length" },
        { text: "Number of contexts", value: "contexts.length" },
        { text: "Created at", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      position: {
        name: "",
        description: "",
        publicName: "",
        publicDescription: "",
        jobId: null,
        interviewTypeId: null,
        competenciesIds: [],
        contexts: [],
        status: 1,
      },
      rules: [(v) => !!v || "Required field"],
      createPositionDialog: false,
      creatingPosition: false,
      editPositionDialog: false,
      editingPosition: false,
      deletePositionDialog: false,
      deletingPosition: false,
      itemToEdit: {},
      itemToDelete: {},
      itemToShow: {},
      headersDetails: [
        { text: "Name", value: "name" },
        { text: "Content", value: "content" },
      ],
      headersDetailsCompetencies: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Category", value: "category" },
      ],
      showDetailsPositionDialog: false,
    };
  },
  async mounted() {
    await this.getAllJobs();
    await this.getAllInterviewTypes();
    await this.getAllCompetencies();
    await this.getAllContexts();
    // await this.getAllUsers();
    await this.getAllPositions();
  },
  methods: {
    getAllPositions() {
      this.loading = true;

      PositionsDataService.findAll().then(
        (response) => {
          if (response.status == 200) this.positions = response.data.data;
          else {
            this.successfullPositions = false;
            this.messagePositions =
              "An error has occurred retrieving the list of positions. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullPositions = false;
          this.messagePositions =
            "An error has occurred retrieving the list of positions. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getAllJobs() {
      this.loading = true;

      JobsDataService.findAll().then(
        (response) => {
          if (response.status == 200) this.jobs = response.data.data;
          else {
            this.successfullJobs = false;
            this.messageJobs =
              "An error has occurred retrieving the list of jobs. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullJobs = false;
          this.messageJobs =
            "An error has occurred retrieving the list of jobs. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getAllInterviewTypes() {
      this.loading = true;

      InterviewsDataService.findAllInterviewTypes().then(
        (response) => {
          if (response.status == 200) this.interviewTypes = response.data.data;
          else {
            this.successfullInterviewTypes = false;
            this.messageInterviewTypes =
              "An error has occurred retrieving the list of interview types. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullInterviewTypes = false;
          this.messageInterviewTypes =
            "An error has occurred retrieving the list of interview types. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getAllCompetencies() {
      this.loading = true;

      CompetenciesDataService.findAll().then(
        (response) => {
          if (response.status == 200) this.competencies = response.data.data;
          else {
            this.successfullCompetencies = false;
            this.messageCompetencies =
              "An error has occurred retrieving the list of competencies. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullCompetencies = false;
          this.messageCompetencies =
            "An error has occurred retrieving the list of competencies. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    getAllContexts() {
      this.loading = true;

      ContextsDataService.findAll().then(
        (response) => {
          if (response.status == 200) this.contexts = response.data.data;
          else {
            this.successfullContexts = false;
            this.messageContexts =
              "An error has occurred retrieving the list of contexts. Please, try again later.";
          }

          this.loading = false;
        },
        (error) => {
          this.successfullContexts = false;
          this.messageContexts =
            "An error has occurred retrieving the list of contexts. Please, try again later.";

          console.error(
            (error.response && error.response.data) ||
              error.message ||
              error.toString()
          );

          if (error.response.status === 401 || error.response.status === 403) {
            localStorage.removeItem("user");
            this.$router.push("/");
          }

          this.loading = false;
        }
      );
    },
    openCreatePosition() {
      this.createPositionDialog = true;
    },
    createPosition() {
      this.successfullCreatePosition = true;
      this.messageCreatePosition = "";

      if (this.$refs.createPositionForm.validate()) {
        if (
          this.position.interviewTypeId !== null &&
          this.position.jobId !== null &&
          this.position.contexts.length > 0 &&
          this.position.competenciesIds.length > 0
        ) {
          this.creatingPosition = true;

          PositionsDataService.create(this.position).then(
            (response) => {
              if (response.status == 201) {
                this.getAllPositions();

                this.messageCreatePosition = "";
                this.successfullCreatePosition = true;

                setTimeout(() => {
                  this.messageCreatePosition = "Position successfully created.";
                }, 10);
              } else {
                this.messageCreatePosition = "";
                this.successfullCreatePosition = true;

                setTimeout(() => {
                  this.messageCreatePosition =
                    "An error has occurred creating the position. Please, try again later.";
                }, 10);
              }

              this.createPositionDialog = false;
              this.creatingPosition = false;
              this.position = {
                name: "",
                description: "",
                publicName: "",
                publicDescription: "",
                jobId: null,
                interviewTypeId: null,
                competenciesIds: [],
                contexts: [],
                status: 1,
              };
            },
            (error) => {
              this.messageCreatePositionInside = "";
              this.messageCreatePosition = "";

              if (error.response.status === 409) {
                this.successfullCreatePositionInside = false;

                setTimeout(() => {
                  this.messageCreatePositionInside =
                    "Position already in use. Please, change its name.";
                }, 10);
              } else if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                localStorage.removeItem("user");
                this.$router.push("/");
              } else {
                this.successfullCreatePosition = false;

                setTimeout(() => {
                  this.messageCreatePosition =
                    "An error has occurred creating the position. Please, try again later.";
                }, 10);

                this.createPositionDialog = false;
                this.position = {
                  name: "",
                  description: "",
                  publicName: "",
                  publicDescription: "",
                  jobId: null,
                  interviewTypeId: null,
                  competenciesIds: [],
                  contexts: [],
                  status: 1,
                };
              }

              console.error(
                (error.response && error.response.data) ||
                  error.message ||
                  error.toString()
              );

              this.creatingPosition = false;
            }
          );
        } else {
          this.messageCreatePositionInside = "";
          this.successfullCreatePositionInside = false;

          setTimeout(() => {
            this.messageCreatePositionInside = "Please, fill all fields.";
          }, 10);
        }
      }
    },
    cancelCreatePosition() {
      this.createPositionDialog = false;
      this.position = {
        name: "",
        description: "",
        publicName: "",
        publicDescription: "",
        jobId: null,
        interviewTypeId: null,
        competenciesIds: [],
        contexts: [],
        status: 1,
      };
    },
    openEditPosition(position) {
      this.itemToEdit = { ...position };

      let itemContexts = [...position.contexts];

      this.itemToEdit.contexts = [];
      itemContexts.forEach((context) => {
        this.itemToEdit.contexts.push(context.id);
      });

      this.itemToEdit.jobId = this.itemToEdit.job.id;
      this.itemToEdit.interviewTypeId = this.itemToEdit.interviewType.id;

      this.itemToEdit.competenciesIds = [];

      this.itemToEdit.competencies.forEach((competence) => {
        this.itemToEdit.competenciesIds.push(competence.id);
      });

      this.editPositionDialog = true;
    },
    editPosition() {
      this.successfullEditPosition = true;

      if (this.$refs.editPositionForm.validate()) {
        this.editingPosition = true;

        PositionsDataService.update(this.itemToEdit).then(
          (response) => {
            if (response.status == 204) {
              this.getAllPositions();
              this.messageEditPosition = "";
              this.successfullEditPosition = true;

              setTimeout(() => {
                this.messageEditPosition = "Position successfully updated.";
              }, 10);
            } else {
              this.messageEditPosition = "";
              this.successfullEditPosition = true;

              setTimeout(() => {
                this.messageEditPosition =
                  "An error has occurred updating the position. Please, try again later.";
              }, 10);
            }

            this.editPositionDialog = false;
            this.editingPosition = false;
            this.itemToEdit = {};
          },
          (error) => {
            this.messageEditPositionInside = "";
            this.messageEditPosition = "";

            if (error.response.status === 409) {
              this.successfullEditPositionInside = false;

              setTimeout(() => {
                this.messageEditPositionInside =
                  "Position already in use. Please, change its name.";
              }, 10);
            } else if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            } else {
              this.successfullEditPosition = false;

              setTimeout(() => {
                this.messageEditPosition =
                  "An error has occurred updating the position. Please, try again later.";
              }, 10);

              this.editPositionDialog = false;
              this.itemToEdit = {};
            }

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            this.editingPosition = false;
          }
        );
      }
    },
    openDeletePosition(position) {
      this.itemToDelete = { ...position };
      this.deletePositionDialog = true;
    },
    deletePosition() {
      if (this.itemToDelete.id !== undefined) {
        this.deletingPosition = true;

        PositionsDataService.delete(this.itemToDelete.id).then(
          (response) => {
            if (response.status == 204) {
              this.getAllPositions();
              this.messageDeletePosition = "";
              this.successfullDeletePosition = true;

              setTimeout(() => {
                this.messageDeletePosition = "Position successfully deleted.";
              }, 10);
            } else {
              this.messageDeletePosition = "";
              this.successfullDeletePosition = false;

              setTimeout(() => {
                this.messageDeletePosition =
                  "An error has occurred deleting the position. Please, try again later.";
              }, 10);
            }

            this.deletePositionDialog = false;
            this.deletingPosition = false;
            this.itemToDelete = {};
          },
          (error) => {
            this.messageDeletePosition = "";
            this.successfullDeletePosition = false;

            setTimeout(() => {
              this.messageDeletePosition =
                "An error has occurred deleting the position. Please, try again later.";
            }, 10);

            this.deletePositionDialog = false;
            this.itemToDelete = {};

            console.error(
              (error.response && error.response.data) ||
                error.message ||
                error.toString()
            );

            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              localStorage.removeItem("user");
              this.$router.push("/");
            }

            this.deletingPosition = false;
          }
        );
      } else {
        this.messageDeletePosition = "";
        this.successfullDeletePosition = false;

        setTimeout(() => {
          this.messageDeletePosition =
            "An error has occurred deleting the position. Please, try again later.";
        }, 10);

        this.deletePositionDialog = false;
        this.itemToDelete = {};
      }
    },
    openShowDetails(position) {
      this.itemToShow = { ...position };
      this.showDetailsPositionDialog = true;
    },
  },
};
</script>
